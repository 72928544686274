// Unauthorized.js
import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

const Unauthorized = () =>
{
    return (
        <Result
            status="403"
            title="403"
            subTitle='Anda harus login untuk mengakses halaman ini!'
            extra={
                <Button type="primary">
                    <Link to='/'>
                        Kembali ke halaman utama
                    </Link>
                </Button>
            }
        />
    );
};

export default Unauthorized;
