import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Spin, Select, InputNumber, DatePicker } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs'; // Pastikan dayjs telah diimpor dengan benar
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const PenambahanLahan = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const [ listLahan, setListLahan ] = useState( [] );
    const [ plotCode, setPlotCode ] = useState();
    const fetchListLahan = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/lahan` );
            setListLahan( response.data );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal memuat daftar lahan' );
        } finally
        {
            setLoading( false );
        }
    };
    useEffect( () =>
    {
        fetchListLahan();
    }, [] );
    const handleLokasiLahan = ( value ) =>
    {
        setPlotCode( value );
    }
    const onFinish = async ( value ) =>
    {
        const newLandCode = value.landLocation + value.landCode;
        const isDuplicate = listLahan.find(
            ( lahan ) => lahan.landLocation === value.landLocation && lahan.landCode === newLandCode
        );
        if ( isDuplicate )
        {
            message.error(
                `Data Lahan ${ newLandCode } sudah ada di lokasi ${ value.landLocation }`
            );
            return;
        }
        const newData = {
            ...value,
            farmerName: value.farmerName,
            surfaceArea: value.surfaceArea,
            landCode: newLandCode,
            timePeriod: value.timePeriod,
            landPrice: value.landPrice,
            landLocation: value.landLocation,
            seriousPaySign: value.seriousPaySign,
            seriousDateSign: dayjs( value.seriousDateSign ).format( 'DD/MM/YYYY' ),
            bookingValue: value.bookingValue,
            bookingDate: dayjs( value.bookingDate ).format( 'DD/MM/YYYY' ),
        };
        try
        {
            setLoading( true );
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/lahan`, newData );
            message.success( 'Daftar lahan baru berhasil ditambahkan' );
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            message.error( 'Gagal menambahkan daftar lahan baru' );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="farmerName"
                        label="Nama Petani"
                        rules={ [ { required: true, message: 'Nama petani diperlukan!' } ] }
                    >
                        <Input placeholder="Nama Petani" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="landLocation"
                        label="Lokasi Lahan"
                        rules={ [ { required: true, message: 'Lokasi lahan diperlukan!' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Lokasi Lahan"
                            onChange={ handleLokasiLahan }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG', label: 'TANJUNG' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="landCode"
                        label="Kode Lahan"
                        rules={ [ { required: true, message: 'Kode lahan diperlukan!' } ] }
                    >
                        <InputNumber
                            maxLength={ 1 }
                            style={ { width: '100%' } }
                            placeholder="Kode Lahan"
                            addonBefore={ plotCode } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="landPrice"
                        label="Harga Lahan"
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder="Harga Lahan" />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="timePeriod"
                        label="Tenor Lahan"
                    >
                        <InputNumber
                            addonAfter='Bulan'
                            style={ { width: '100%' } }
                            placeholder="Jumlah Tenor" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="surfaceArea"
                        label="Luas Lahan"
                    >
                        <InputNumber
                            style={ { width: '100%' } }
                            placeholder="Luas Lahan"
                            addonAfter='M²' />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="seriousPaySign"
                        label="Jumlah Tanda Serius"
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder="Jumlah Tanda Serius" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="bookingValue"
                        label="Jumlah Tanda Jadi"
                    >
                        <InputNumber
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                            placeholder="Jumlah Tanda Jadi" />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="seriousDateSign"
                        label="Tanggal Tanda Serius"
                    >
                        <DatePicker
                            format='DD/MM/YYYY'
                            style={ { width: '100%' } }
                            placeholder="Pilih Tanggal" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="bookingDate"
                        label="Tanggal Tanda Jadi"
                    >
                        <DatePicker
                            format='DD/MM/YYYY'
                            style={ { width: '100%' } }
                            placeholder="Pilih Tanggal" />
                    </Form.Item>
                </div>
                <Button type='primary' htmlType='submit' >
                    Tambah Lahan
                </Button>
            </Form>
        </Spin>
    );
};

export default PenambahanLahan;
