import React, { useEffect, useState } from 'react';
import { Button, Drawer, Table, message, InputNumber, DatePicker, Select } from 'antd';
import axios from 'axios';
import { CiEdit } from 'react-icons/ci';
import PayEmployeeSalary from './bayarGajiKaryawan';
import EditEmployeeSalary from './editGajiKaryawan';
import dayjs from 'dayjs';
import { SlRefresh } from 'react-icons/sl';
import { PiCursorClickThin } from 'react-icons/pi';
import NewEmployee from './tambahKaryawan';

const EmployeeSalaryData = () =>
{
    const [ defaultData, setDefaultData ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ drawerEditGaji, setDrawerEditGaji ] = useState( false );
    const [ drawerNew, setDrawerNew ] = useState( false );
    const [ selectedAction, setSelectedAction ] = useState();
    const [ drawerBayarGaji, setDrawerBayarGaji ] = useState( false );
    const [ selectedYear, setSelectedYear ] = useState( dayjs() );
    const [ totalYearSalary, setTotalYearSalary ] = useState();
    const [ salaryId, setSalaryId ] = useState( null );
    const [ employeeId, setEmployeeId ] = useState( null );
    const [ modifData, setModifData ] = useState( [] );
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/employeeList` );
            setDefaultData( response.data )
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        }
        setLoading( false );
    };
    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const handleYearChange = ( date ) =>
    {
        setSelectedYear( date );
    }
    useEffect( () =>
    {
        const tableData = defaultData.map( db =>
        {
            const salaryData = db.dataSalary.filter( f =>
                dayjs( f.dateSalary, 'DD/MM/YYYY' ).isSame( selectedYear, 'year' ) && f.dateSalary
            );
            if ( salaryData.length === 0 ) return null;
            const totalSalaryPerEmployee = salaryData.reduce( ( total, itm ) =>
                total + itm.salary + ( itm.additionalSalary || 0 ), 0 );
            return {
                employeeID: db._id,
                salaryID: salaryData.map( sd => sd._id ),
                employeeName: db.employeeName,
                salary: salaryData.map( itm => itm.salary ),
                additionalSalary: salaryData.map( itm => ( itm.additionalSalary || 0 ) ),
                totalSalary: totalSalaryPerEmployee,
                dateSalary: salaryData.map( itm => itm.dateSalary ),
                salaryMethod: salaryData.map( itm => itm.salaryMethod ),
            };
        } ).filter( item => item !== null );
        const yearSalary = tableData.reduce( ( total, item ) => total + item.totalSalary, 0 );
        setModifData( tableData );
        setTotalYearSalary( yearSalary );
    }, [ defaultData, selectedYear ] );

    const columns = [
        {
            title: 'Aksi',
            key: 'action',
            width: '7%',
            align: 'center',
            render: ( text, record ) => (
                <Button type='default' onClick={ () => handleEditGaji( record.employeeID, record.salaryID ) }
                    icon={ <CiEdit /> } />
            ),
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Karyawan</p>,
            dataIndex: 'employeeName',
            align: 'left',
            width: '20vw',
        },
        {
            title: 'Rincian Gaji',
            width: 'auto',
            align: 'center',
            children: [
                {
                    title: 'Jumlah Gaji',
                    dataIndex: 'salary',
                    width: 'auto',
                    align: 'center',
                    render: value => value ? convertToIDR( value ) : 0,
                },
                {
                    title: 'Tambahan Gaji',
                    dataIndex: 'additionalSalary',
                    width: 'auto',
                    align: 'center',
                    render: value => value ? convertToIDR( value ) : 0,
                },
                {
                    title: 'Total Gaji',
                    dataIndex: 'totalSalary',
                    width: 'auto',
                    align: 'center',
                    render: value => convertToIDR( value )
                },
                {
                    title: 'Tanggal Gaji',
                    dataIndex: 'dateSalary',
                    width: 'auto',
                    align: 'center',
                    sorter: ( a, b ) => dayjs( a.dateSalary, 'DD/MM/YYYY' ) - dayjs( b.dateSalary, 'DD/MM/YYYY' ),
                },
                {
                    title: 'Metode Gaji',
                    dataIndex: 'salaryMethod',
                    width: 'auto',
                    align: 'center',
                },
            ]
        }
    ];
    const handleDrawerNew = () =>
    {
        setDrawerNew( true );
    };
    const handleCloseDrawerNew = () =>
    {
        setDrawerNew( false );
    };
    const handleCloseBayarGaji = () =>
    {
        setDrawerBayarGaji( false );
    };
    const handleOpenBayarGaji = () =>
    {
        setDrawerBayarGaji( true );
    };
    const handleEditGaji = ( employeeID, salaryID ) =>
    {
        setEmployeeId( employeeID );
        setSalaryId( salaryID );
        setDrawerEditGaji( true );
    };
    const handleCloseEditGaji = () =>
    {
        setEmployeeId( null );
        setSalaryId( null );
        setDrawerEditGaji( false );
    };
    const handleRefresh = () =>
    {
        fetchData();
    };
    const handleSelectChange = ( value ) =>
    {
        if ( value )
        {
            setSelectedAction( value );
        } else
        {
            setSelectedAction( null );
        }
    };
    const handleSelectAction = () =>
    {
        if ( selectedAction === 'bayarGaji' )
        {
            handleOpenBayarGaji();
        }
        if ( selectedAction === 'tambahKaryawan' )
        {
            handleDrawerNew();
        }
    };
    return (
        <div >
            <div style={ { padding: '0 5px' } }>
                <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                    <Button style={ { padding: 5 } } onClick={ handleRefresh } icon={ <SlRefresh /> } />
                    <Button
                        style={ { padding: 5 } }
                        type='primary'
                        onClick={ handleSelectAction }
                        icon={ <PiCursorClickThin /> } />
                    <Select
                        style={ { width: '30%' } }
                        placeholder='Pilih Aksi'
                        onChange={ handleSelectChange }
                        allowClear
                        options={ [
                            { value: 'bayarGaji', label: 'Bayar Gaji' },
                            { value: 'tambahKaryawan', label: 'Tambah Karyawan' },
                        ] } />
                    <InputNumber
                        readOnly
                        style={ { width: '100%' } }
                        addonBefore={ totalYearSalary >= 1 && `Gaji Tahun ${ dayjs( selectedYear ).format( 'YYYY' ) }` }
                        value={ totalYearSalary >= 1 && convertToIDR( totalYearSalary ) }
                        placeholder={ totalYearSalary < 1 && 'Tidak ada pengeluaran gaji' } />
                    <DatePicker.YearPicker
                        style={ { width: '30%' } }
                        onChange={ handleYearChange }
                        defaultValue={ selectedYear }
                        allowClear={ false }
                        picker="year"
                        placeholder='Pilih Tahun'
                    />
                </div>
                <Table
                    bordered
                    columns={ columns }
                    showSorterTooltip={ false }
                    dataSource={ modifData }
                    rowKey="_id"
                    loading={ loading }
                    size="small"
                    scroll={ { y: 350 } }
                />
            </div>
            <div>
                <Drawer
                    title="Edit Gaji"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEditGaji }
                    visible={ drawerEditGaji }
                    width="40%"
                >
                    <EditEmployeeSalary
                        salaryId={ salaryId }
                        employeeId={ employeeId }
                        onClose={ handleCloseEditGaji }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Bayar Gaji Karyawan"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseBayarGaji }
                    visible={ drawerBayarGaji }
                    width="30%"
                >
                    <PayEmployeeSalary onClose={ handleCloseBayarGaji } fetchData={ fetchData } />
                </Drawer>
                <Drawer
                    title="Tambah Karyawan"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseDrawerNew }
                    visible={ drawerNew }
                    width="30%"
                >
                    <NewEmployee onClose={ handleCloseDrawerNew } fetchData={ fetchData } />
                </Drawer>
            </div>
        </div>
    );
};

export default EmployeeSalaryData;
