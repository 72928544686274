import React, { useEffect, useState } from 'react';
import { Table, Button, DatePicker, Drawer, Select, message, InputNumber } from 'antd';
import axios from 'axios';
import { PiCursorClickThin } from 'react-icons/pi';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import 'dayjs/locale/id'; // Import bahasa Indonesia untuk dayjs
import { SlRefresh } from 'react-icons/sl';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import PayCashTempo from './bayarCashTempo';
import EditCashTempo from './editCashTempo';
dayjs.locale( 'id' ); // Set lokal dayjs ke bahasa Indonesia
dayjs.extend( isSameOrBefore );
const CashTempoData = () =>
{
    const [ loading, setLoading ] = useState( false );
    const [ drawerEdit, setDrawerEdit ] = useState( false );
    const [ customerData, setCustomerData ] = useState( [] );
    const [ pembayaranBlmDibayar, setPembayaranBlmDibayar ] = useState( 0 );
    const [ pembayaranDibayar, setPembayaranDibayar ] = useState( 0 );
    const [ selectedAction, setSelectedAction ] = useState( "" );
    const [ filterLocation, setFilterLocation ] = useState( [] );
    const [ drawerPayment, setDrawerPayment ] = useState( false );
    const [ filteredData, setFilteredData ] = useState( [] );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/customers` );
            const updateData = response.data
                .filter( f => f.pembayaranTempo );
            setCustomerData( updateData );
            setFilterLocation( getUniqueLocation( updateData ) );
        } catch ( error )
        {
            console.error( 'Error fetching data:', error );
        } finally
        {
            setLoading( false );
        }
    };
    const handleRefresh = () =>
    {
        fetchData();
    };
    const getUniqueLocation = filteredData =>
    {
        const uniqueTypes = [ ...new Set( filteredData.map( item => item.lokasiKavling ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const handleSelectMonth = ( value ) =>
    {
        setSelectedMonth( value );
    }
    useEffect( () =>
    {
        fetchData()
    }, [] )
    useEffect( () =>
    {
        if ( selectedMonth )
        {
            const startOfMonth = dayjs( selectedMonth ).startOf( 'month' );
            const endOfMonth = dayjs( selectedMonth ).endOf( 'month' );
            const filterData = customerData.map( db =>
            {
                const pembayaranTempoFiltered = db.pembayaranTempo.filter( item =>
                    dayjs( item.tanggalByrTempo, 'DD/MM/YYYY' ).isSame( selectedMonth, 'month' )
                );
                const firstAngsuran = db.pembayaranTempo[ 0 ]?.tanggalByrTempo;
                return {
                    pembayaran1: firstAngsuran,
                    nama: db.namaCustomer,
                    blok: ( db.blokKavling === 'TANPABLOK' ? db.noKavling : db.blokKavling + db.noKavling ),
                    lokasi: db.lokasiKavling,
                    sisa: ( db.hargaKavling - ( db.jumlahUTJ || 0 ) - ( db.uangMukaKavling || 0 ) ) - db.pembayaranTempo
                        .filter( i => dayjs( i.tanggalByrTempo, 'DD/MM/YYYY' ).isSameOrBefore( endOfMonth ) )
                        .reduce( ( total, item ) => total + ( item.jumlahByrTempo || 0 ), 0 ),
                    jumlahByr: pembayaranTempoFiltered.length > 0
                        ? pembayaranTempoFiltered.map( item => convertToIDR( item.jumlahByrTempo ) ).join( ", " )
                        : convertToIDR( 0 ),
                    tanggalByr: pembayaranTempoFiltered.length > 0
                        ? pembayaranTempoFiltered.map( item => item.tanggalByrTempo ).join( ", " )
                        : '',
                    metodeByr: pembayaranTempoFiltered.length > 0
                        ? pembayaranTempoFiltered.map( item => item.metodeByrTempo ).join( ", " )
                        : '',
                    bayarTmpKe: pembayaranTempoFiltered.length > 0
                        ? pembayaranTempoFiltered.map( item => item.bayarTempoKe ).join( ", " )
                        : ''
                };
            } );
            const setData = filterData.filter( item =>
                dayjs( item.pembayaran1, 'DD/MM/YYYY' ).isSameOrBefore( endOfMonth )
            );
            const totalByrAngs = setData.reduce( ( total, item ) =>
            {
                const jumlahByrNumbers = item.jumlahByr
                    .split( ', ' )
                    .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                    .filter( value => !isNaN( value ) );
                return total + jumlahByrNumbers.reduce( ( acc, value ) => acc + value, 0 );
            }, 0 );
            const totalBlmByr = setData.filter( db => db.jumlahByr === convertToIDR( 0 ) )
                .reduce( ( total, item ) =>
                {
                    return total + ( item.angsuran || 0 );
                }, 0 );
            setFilteredData( setData );
            setPembayaranBlmDibayar( totalBlmByr );
            setPembayaranDibayar( totalByrAngs );
        } else
        {
            return true;
        }
    }, [ customerData, selectedMonth ] )
    const handleSelectChange = ( value ) =>
    {
        if ( value )
        {
            setSelectedAction( value );
        } else
        {
            setSelectedAction( null );
        }
    }
    const handleSelectAction = () =>
    {
        if ( selectedAction === 'bayar' )
        {
            handlePayment();
        } if ( selectedAction === 'edit' )
        {
            handleEdit();
        }
    };
    const handleEdit = () =>
    {
        setDrawerEdit( true );
    };
    const handleCloseDrawer = () =>
    {
        setDrawerEdit( false );
    };
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };
    const handlePayment = () =>
    {
        setDrawerPayment( true );
    }
    const handleClosePayment = () =>
    {
        setDrawerPayment( false );
    }
    const columns = [
        {
            title: 'Blok Kavling',
            dataIndex: 'blok',
            align: 'center',
            width: '8%',
            sorter: ( a, b ) =>
            {
                const regex = /^(\d+|[a-zA-Z]+)(\d+|[a-zA-Z]*)$/;
                const aMatch = a.blok.match( regex );
                const bMatch = b.blok.match( regex );

                if ( !aMatch || !bMatch ) return 0;

                const aPart1 = aMatch[ 1 ];
                const aPart2 = aMatch[ 2 ] || '';
                const bPart1 = bMatch[ 1 ];
                const bPart2 = bMatch[ 2 ] || '';

                if ( isNaN( aPart1 ) && isNaN( bPart1 ) )
                {
                    // Both parts are strings
                    if ( aPart1 !== bPart1 )
                    {
                        return aPart1.localeCompare( bPart1 );
                    }
                    return aPart2.localeCompare( bPart2 );
                }

                if ( !isNaN( aPart1 ) && !isNaN( bPart1 ) )
                {
                    // Both parts are numbers
                    const numCompare = parseInt( aPart1 ) - parseInt( bPart1 );
                    if ( numCompare !== 0 )
                    {
                        return numCompare;
                    }
                    return aPart2.localeCompare( bPart2 );
                }
                return isNaN( aPart1 ) ? 1 : -1;
            },
            sortDirections: [ 'descend', 'ascend' ],
            defaultSortOrder: 'ascend',
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Pelanggan</p>,
            dataIndex: 'nama',
            align: 'left',
            width: 'auto',
            sorter: ( a, b ) => a.nama.localeCompare( b.nama ),
        },
        {
            title: 'Lokasi',
            dataIndex: 'lokasi',
            align: 'center',
            width: '9%',
            filters: filterLocation,
            onFilter: ( value, record ) => record.lokasi.includes( value ),
        },
        {
            title: 'Sisa Pembayaran',
            dataIndex: 'sisa',
            align: 'center',
            width: '10%',
            render: value => convertToIDR( value ),
        },
        {
            title: 'Pembayaran',
            align: 'center',
            width: 'auto',
            children: [
                {
                    title: 'Ke',
                    dataIndex: 'bayarTmpKe',
                    align: 'center',
                    width: '8%',
                },
                {
                    title: 'Jumlah Bayar',
                    dataIndex: 'jumlahByr',
                    align: 'center',
                    width: '10%',
                },
                {
                    title: 'Tanggal Bayar',
                    dataIndex: 'tanggalByr',
                    align: 'center',
                    width: '10%',
                    sorter: ( a, b ) => a.tanggalByr.localeCompare( b.tanggalByr ),
                },
                {
                    title: 'Metode',
                    dataIndex: 'metodeByr',
                    align: 'center',
                    width: '8%',
                },
            ],
        },
    ];
    // const exportToExcel = () =>
    // {
    //     if ( !filteredData || filteredData.length === 0 )
    //     {
    //         message.error( 'Tidak ada data untuk diekspor' );
    //         return;
    //     }

    //     const ws = XLSX.utils.json_to_sheet( filteredData.map( item => ( {
    //         'Blok': item.blok,
    //         'Nama Pelanggan': item.nama,
    //         'Ke': item.bayarTmpKe,
    //         'Jumlah Bayar': parseFloat( item.jumlahByr.replace( /[^\d]/g, '' ) ) === 0 ? undefined
    //             : parseFloat( item.jumlahByr.replace( /[^\d]/g, '' ) ),
    //         'Tanggal Bayar': item.tanggalByr,
    //         'Metode Bayar': item.metodeByr,
    //     } ) ), { header: [ "Blok", "Nama Pelanggan", "Angsuran", "Tenor", "Sisa", "Ke", "Jumlah Bayar", "Tanggal Bayar", "Metode Bayar" ] } );

    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet( wb, ws, 'Data Customers' );
    //     XLSX.writeFile( wb, `Data Angsuran ${ selectedMonth.format( 'MMMM YYYY' ) }.xlsx` );
    // };
    return (
        <div style={ { padding: '0 5px' } }>
            <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                <Button
                    style={ { padding: 5 } }
                    onClick={ handleRefresh }
                    icon={ <SlRefresh /> } />
                <Button
                    style={ { padding: 5 } }
                    type='primary'
                    onClick={ handleSelectAction }
                    icon={ <PiCursorClickThin /> } />
                <Select
                    style={ { width: '50%' } }
                    placeholder='Pilih Aksi'
                    onChange={ handleSelectChange }
                    allowClear
                    options={ [
                        { value: 'bayar', label: 'Bayar Pembayaran' },
                        { value: 'edit', label: 'Edit Pembayaran' },
                    ] } />
                <InputNumber
                    style={ { width: '100%' } }
                    value={ pembayaranDibayar >= 1 && convertToIDR( pembayaranDibayar ) }
                    placeholder={ pembayaranDibayar < 1 && 'Tidak Ada Pembayaran' }
                    addonBefore={ pembayaranDibayar >= 1 && 'Terbayar' }
                    readOnly />
                <InputNumber
                    style={ { width: '100%' } }
                    value={ pembayaranBlmDibayar >= 1 && convertToIDR( pembayaranBlmDibayar ) }
                    placeholder={ pembayaranBlmDibayar < 1 && 'Tidak Ada Pembayaran Tertagih' }
                    addonBefore={ pembayaranBlmDibayar >= 1 && 'Tertagih' }
                    readOnly />
                <DatePicker.MonthPicker
                    style={ { width: '50%' } }
                    onChange={ handleSelectMonth }
                    allowClear={ false }
                    value={ selectedMonth }
                    picker="month"
                    format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                    placeholder='Pilih Bulan'
                />
            </div>
            <Table
                size="small"
                pagination={ { pageSize: 20 } }
                scroll={ { y: 340 } }
                dataSource={ filteredData }
                columns={ columns }
                rowKey="_id"
                loading={ loading }
                showSorterTooltip={ false }
            />
            <Drawer
                title="Edit Angsuran"
                placement="right"
                closable={ true }
                onClose={ handleCloseDrawer }
                visible={ drawerEdit }
                width='40%'
            >
                <EditCashTempo
                    onClose={ handleCloseDrawer }
                    fetchData={ fetchData }
                />
            </Drawer>
            <Drawer
                title="Bayar Angsuran"
                placement="right"
                closable={ true }
                onClose={ handleClosePayment }
                visible={ drawerPayment }
                width='40%'
            >
                <PayCashTempo
                    onClose={ handleClosePayment }
                    fetchData={ fetchData }
                />
            </Drawer>
        </div>
    );
};

export default CashTempoData;
