import React, { useEffect, useState } from 'react';
import { Form, Button, InputNumber, DatePicker, Select, message, Tooltip, Collapse } from 'antd';
import axios from 'axios';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const { Option } = Select;
const { Panel } = Collapse;

const BayarGaji = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const days = [ 'senin', 'selasa', 'rabu', 'kamis', 'jumat', 'sabtu', 'minggu' ];
    const [ employee, setEmployee ] = useState( [] );
    const [ dailyWages, setDailyWages ] = useState( {} );
    const [ loading, setLoading ] = useState( false );

    const listEmployee = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/pekerja` );
            setEmployee( response.data );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal memuat data pekerja' );
        } finally
        {
            setLoading( false );
        }
    };

    useEffect( () =>
    {
        listEmployee();
        addInitialEntries( 'gajiHarian' );
        addInitialEntries( 'gajiLembur' );
    }, [] );

    const addInitialEntries = ( listName ) =>
    {
        form.setFieldsValue( {
            [ listName ]: [ { nama: '', posisiPekerja: '', senin: 0, selasa: 0, rabu: 0, kamis: 0, jumat: 0, sabtu: 0, minggu: 0 } ],
        } );
    };
    const onFinish = ( values ) =>
    {
        let totalGajiMingguan = 0;
        let totalGajiLembur = 0;

        // Pastikan values.gajiHarian dan values.gajiLembur adalah array
        const gajiHarian = values.gajiHarian || [];
        const gajiLembur = values.gajiLembur || [];

        // Hitung total gaji mingguan
        gajiHarian.forEach( ( item ) =>
        {
            days.forEach( ( day ) =>
            {
                totalGajiMingguan += item[ day ] || 0; // Gunakan nilai default 0 jika tidak ada nilai
            } );
        } );

        // Hitung total gaji lembur
        gajiLembur.forEach( ( item ) =>
        {
            days.forEach( ( day ) =>
            {
                totalGajiLembur += item[ day ] || 0; // Gunakan nilai default 0 jika tidak ada nilai
            } );
        } );

        const totalPembayaranGaji = totalGajiLembur + totalGajiMingguan + values.uangMakanTukang;

        const paymentData = {
            ...values,
            tanggalGaji: values.tanggalGaji ? values.tanggalGaji.format( 'DD/MM/YYYY' ) : null, // Pastikan tanggal terformat dengan baik
            totalGajiMingguan,
            totalGajiLembur,
            totalPembayaranGaji,
        };

        // Lakukan request axios untuk menyimpan data
        try
        {
            setLoading( true );
            axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/gajiTukang`, paymentData );
            form.resetFields();
            message.success( 'Pembayaran gaji berhasil ditambahkan' );
            setLoading( false );
            fetchData();
            onClose();
        } catch ( error )
        {
            message.error( 'Gagal menambahkan gaji tukang' );
        } finally
        {
            setLoading( false );
        }
    };


    const onPosisiChange = ( value, index ) =>
    {
        const wageOptions = {
            baru: 115000,
            kuli: 125000,
            tukang: 140000,
        };
        const wage = wageOptions[ value ];
        const updatedWages = days.reduce( ( acc, day ) =>
        {
            acc[ day ] = day === 'jumat' ? 0 : wage;
            return acc;
        }, {} );

        setDailyWages( ( prevWages ) => ( {
            ...prevWages,
            [ index ]: updatedWages,
        } ) );

        const currentFieldsValue = form.getFieldsValue();
        const updatedFields = currentFieldsValue.gajiHarian.map( ( item, idx ) =>
        {
            if ( idx === index )
            {
                return { ...item, ...updatedWages, posisiPekerja: value };
            }
            return item;
        } );

        form.setFieldsValue( {
            gajiHarian: updatedFields,
        } );
    };

    const handleWageChange = ( index, day, value ) =>
    {
        setDailyWages( ( prevWages ) => ( {
            ...prevWages,
            [ index ]: {
                ...prevWages[ index ],
                [ day ]: value,
            },
        } ) );

        const currentFieldsValue = form.getFieldsValue();
        const updatedFields = currentFieldsValue.gajiHarian.map( ( item, idx ) =>
        {
            if ( idx === index )
            {
                return { ...item, [ day ]: value };
            }
            return item;
        } );

        form.setFieldsValue( {
            gajiHarian: updatedFields,
        } );
    };
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
        } ).format( value );
    };
    const optionsWithTooltip = [
        { value: 'baru', label: 'BARU', tooltip: convertToIDR( 115000 ) },
        { value: 'kuli', label: 'KULI', tooltip: convertToIDR( 125000 ) },
        { value: 'tukang', label: 'TUKANG', tooltip: convertToIDR( 140000 ) },
    ];

    return (
        <>
            <Form form={ form } name='post_form' onFinish={ onFinish } layout='vertical'>
                <Collapse defaultActiveKey={ [ '3' ] } accordion>
                    <Panel header='Gaji Mingguan' key='1'>
                        <Form.List name='gajiHarian'>
                            { ( fields, { add, remove } ) => (
                                <>
                                    { fields.map( ( { key, name, fieldKey, ...restField }, index ) => (
                                        <div key={ key }>
                                            <div style={ { display: 'flex', gap: 5 } }>
                                                <Form.Item
                                                    { ...restField }
                                                    name={ [ name, 'nama' ] }
                                                    style={ { width: '100%' } }
                                                    fieldKey={ [ fieldKey, 'nama' ] }
                                                    label='Nama Pekerja'
                                                    rules={ [ { required: true, message: 'Nama pekerja diperlukan!' } ] }
                                                    normalize={ ( value ) => value && value.toUpperCase() }
                                                >
                                                    <Select
                                                        placeholder='Pilih Pekerja'
                                                        style={ { width: '100%' } }
                                                        options={ employee.map( ( emp ) => ( {
                                                            value: emp.nama,
                                                            label: emp.nama,
                                                        } ) ) }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    { ...restField }
                                                    name={ [ name, 'posisiPekerja' ] }
                                                    fieldKey={ [ fieldKey, 'posisiPekerja' ] }
                                                    label='Posisi Pekerja'
                                                    style={ { width: '100%' } }
                                                    rules={ [ { required: true, message: 'Opsi diperlukan!' } ] }
                                                >
                                                    <Select
                                                        placeholder='Pilih Opsi Gaji'
                                                        style={ { width: '100%' } }
                                                        onChange={ ( value ) => onPosisiChange( value, index ) }
                                                    >
                                                        { optionsWithTooltip.map( ( option ) => (
                                                            <Option key={ option.value } value={ option.value }>
                                                                <Tooltip title={ option.tooltip }>{ option.label }</Tooltip>
                                                            </Option>
                                                        ) ) }
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div
                                                style={ {
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(7, 1fr)',
                                                    gap: '5px',
                                                } }
                                            >
                                                { days.map( ( day ) => (
                                                    <Form.Item
                                                        key={ day }
                                                        label={ `Gaji ${ day.charAt( 0 ).toUpperCase() + day.slice( 1 ) }` }
                                                        name={ [ name, day ] }
                                                        fieldKey={ [ fieldKey, day ] }
                                                    >
                                                        <InputNumber
                                                            value={ dailyWages[ index ]?.[ day ] ?? 0 }
                                                            onChange={ ( value ) => handleWageChange( index, day, value ) }
                                                            style={ { width: '100%' } }
                                                            min={ 0 }
                                                            addonBefore='Rp'
                                                            placeholder='Masukkan gaji'
                                                        />
                                                    </Form.Item>
                                                ) ) }
                                            </div>
                                            <Button
                                                style={ { marginBottom: '10px' } }
                                                danger
                                                onClick={ () => remove( name ) }
                                                block
                                            >
                                                { `Hapus Gaji Pekerja ${ index + 1 }` }
                                            </Button>
                                        </div>
                                    ) ) }
                                    <Button onClick={ () => add() } block>
                                        Tambah Gaji Pekerja
                                    </Button>
                                </>
                            ) }
                        </Form.List>
                    </Panel>
                    <Panel header='Gaji Lembur' key='2'>
                        <Form.List name='gajiLembur'>
                            { ( fields, { add, remove } ) => (
                                <>
                                    { fields.map( ( { key, name, fieldKey, ...restField }, index ) => (
                                        <div key={ key }>
                                            <div style={ { display: 'flex', gap: 5 } }>
                                                <Form.Item
                                                    { ...restField }
                                                    name={ [ name, 'nama' ] }
                                                    style={ { width: '100%' } }
                                                    fieldKey={ [ fieldKey, 'nama' ] }
                                                    label='Nama Pekerja'
                                                    rules={ [ { required: true, message: 'Nama pekerja diperlukan!' } ] }
                                                    normalize={ ( value ) => value && value.toUpperCase() }
                                                >
                                                    <Select
                                                        placeholder='Pilih Pekerja'
                                                        style={ { width: '100%' } }
                                                        options={ employee.map( ( emp ) => ( {
                                                            value: emp.nama,
                                                            label: emp.nama,
                                                        } ) ) }
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div
                                                style={ {
                                                    display: 'grid',
                                                    gridTemplateColumns: 'repeat(7, 1fr)',
                                                    gap: '5px',
                                                } }
                                            >
                                                { days.map( ( day ) => (
                                                    <Form.Item
                                                        key={ day }
                                                        label={ `Gaji Lembur ${ day.charAt( 0 ).toUpperCase() + day.slice( 1 ) }` }
                                                        name={ [ name, day ] }
                                                        fieldKey={ [ fieldKey, day ] }
                                                    >
                                                        <InputNumber
                                                            style={ { width: '100%' } }
                                                            min={ 0 }
                                                            addonBefore='Rp'
                                                            placeholder='Masukkan gaji'
                                                        />
                                                    </Form.Item>
                                                ) ) }
                                            </div>
                                            <Button
                                                style={ { marginBottom: '10px' } }
                                                danger
                                                onClick={ () => remove( name ) }
                                                block
                                            >
                                                { `Hapus Gaji Lembur ${ index + 1 }` }
                                            </Button>
                                        </div>
                                    ) ) }
                                    <Button onClick={ () => add() } block>
                                        Tambah Pekerja Lembur
                                    </Button>
                                </>
                            ) }
                        </Form.List>
                    </Panel>
                    <Panel header='Lainnya' key='3'>
                        <div style={ { display: 'flex', gap: 5 } }>
                            <Form.Item
                                style={ { width: '100%' } }
                                name='tanggalGaji'
                                label='Tanggal Pembayaran Gaji'
                                rules={ [ { required: true, message: 'Tanggal gaji diperlukan!' } ] }
                            >
                                <DatePicker
                                    style={ { width: '100%' } }
                                    format='DD/MM/YYYY' />
                            </Form.Item>
                            <Form.Item
                                style={ { width: '100%' } }
                                name='uangMakanTukang'
                                label='Uang Makan'>
                                <InputNumber
                                    style={ { width: '100%' } }
                                    addonBefore='Rp'
                                    placeholder='Masukkan Uang Makan'
                                    min={ 0 }
                                />
                            </Form.Item>
                        </div>
                    </Panel>
                </Collapse>
                <Form.Item style={ { textAlign: 'center', marginTop: '10px' } }>
                    <Button type='primary' htmlType='submit' >
                        Bayar Gaji
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default BayarGaji;
