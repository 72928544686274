import React, { useEffect, useState } from 'react';
import { Button, Drawer, Select, Table, message } from 'antd';
import axios from 'axios';
import { CiEdit, CiSquareInfo } from 'react-icons/ci'
import { SlRefresh } from 'react-icons/sl';
import PenambahanLahan from './tambahLahan';
import BayarLahan from './lahanBayar';
import DataLahanCek from './cekDataLahan';
import EditDataLahan from './lahanEdit';
import { PiCursorClickThin } from 'react-icons/pi';
import EditBayarLahan from './editBayarLahan';

const LahanData = () =>
{
    const [ listLahan, setListLahan ] = useState( [] );
    const [ drawerTambahLahan, setDrawerTambahLahan ] = useState( false );
    const [ loading, setLoading ] = useState( false );
    const [ drawerEditDataLahan, setDrawerEditDataLahan ] = useState( false );
    const [ selectedAction, setSelectedAction ] = useState( "" );
    const [ editRecordId, setEditRecordId ] = useState( null );
    const [ drawerBayarLahan, setDrawerBayarLahan ] = useState( false );
    const [ drawerCheckData, setDrawerCheckData ] = useState( false );
    const [ drawerEditPembayaran, setDrawerEditPembayaran ] = useState( false );
    const fetchData = async () =>
    {
        try
        {
            setLoading( true );
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/lahan` );
            const data = response.data;
            const tableData = data.map( db => (
                {
                    farmerName: db.farmerName,
                    surfaceArea: db.surfaceArea,
                    landCode: db.landCode,
                    timePeriod: db.timePeriod,
                    landPrice: db.landPrice,
                    landLocation: db.landLocation,
                    amountPaid: ( db.landPayment.reduce( ( total, item ) => total + ( item.jumlahByrLhn || 0 ), 0 ) )
                        + ( db.bookingValue + db.seriousPaySign ),
                    remainingPayment: db.landPrice
                        - ( ( db.landPayment.reduce( ( total, item ) => total + ( item.jumlahByrLhn || 0 ), 0 ) )
                            + ( db.bookingValue + db.seriousPaySign ) ),
                    _id: db._id,
                } ) )
            setListLahan( tableData );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        } finally
        {
            setLoading( false );
        }
    };
    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    const convertToMeters = value =>
    {
        return new Intl.NumberFormat( {
            style: 'currency',
            maximumFractionDigits: 0
        } ).format( value );
    };
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const columns = [
        {
            title: 'Aksi',
            key: 'action',
            width: '10%',
            align: 'center',
            render: ( text, record ) => (
                <div style={ {
                    justifyContent: 'center',
                    display: 'flex',
                    gap: 5
                } }>
                    <Button
                        type='default'
                        onClick={ () => handleEditDataLahan( record._id ) }
                        icon={ <CiEdit /> } />
                    <Button
                        type='default'
                        onClick={ () => handleCheckData( record._id ) }
                        icon={ <CiSquareInfo /> } />
                </div>
            ),
        },
        {
            title: 'No',
            key: 'no',
            width: '5%',
            align: 'center',
            render: ( text, record, index ) => index + 1,
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Petani</p>,
            dataIndex: 'farmerName',
            align: 'left',
            width: '20vw',
        },
        {
            title: 'Kode Lahan',
            dataIndex: 'landCode',
            width: 'auto',
            align: 'center',
        },
        {
            title: 'Luas Lahan',
            dataIndex: 'surfaceArea',
            width: 'auto',
            align: 'center',
            render: value => value ? convertToMeters( value ) + ' M²' : ''
        },
        {
            title: 'Rincian',
            width: 'auto',
            align: 'center',
            children: [
                {
                    title: 'Harga Lahan',
                    dataIndex: 'landPrice',
                    align: 'center',
                    width: 'auto',
                    render: value => convertToIDR( value ),
                },
                {
                    title: 'Sudah Terbayar',
                    dataIndex: 'amountPaid',
                    align: 'center',
                    width: 'auto',
                    render: value => convertToIDR( value ),
                },
                {
                    title: 'Belum Terbayar',
                    dataIndex: 'remainingPayment',
                    align: 'center',
                    width: 'auto',
                    render: value => convertToIDR( value ),
                },
            ]
        },
    ];
    const handleSelectChange = ( value ) =>
    {
        if ( value )
        {
            setSelectedAction( value );
        } else
        {
            setSelectedAction( null );
        }
    }
    const handleSelectAction = () =>
    {
        if ( selectedAction === 'bayarLahan' )
        {
            handleOpenBayarLahan();
        } if ( selectedAction === 'editPembayaran' )
        {
            handleEditByrLahan();
        }
    };
    const handleCloseBayarLahan = () =>
    {
        setDrawerBayarLahan( false );
    }
    const handleOpenBayarLahan = () =>
    {
        setDrawerBayarLahan( true );
    }
    const handleCheckData = recordId =>
    {
        setEditRecordId( recordId );
        setDrawerCheckData( true );
    };
    const handleCloseCheckData = () =>
    {
        setDrawerCheckData( false );
        setEditRecordId( null );
    };
    const handleEditDataLahan = recordId =>
    {
        setEditRecordId( recordId );
        setDrawerEditDataLahan( true );
    };
    const handleCloseEditDrawer = () =>
    {
        setDrawerEditDataLahan( false );
        setEditRecordId( null );
    };
    const handleEditByrLahan = () =>
    {
        setDrawerEditPembayaran( true );
    };
    const handleCloseEditByr = () =>
    {
        setDrawerEditPembayaran( false );
    };
    const handleTambahLahan = () =>
    {
        setDrawerTambahLahan( true );
    };
    const handleCloseTambahLahan = () =>
    {
        setDrawerTambahLahan( false );
    };
    const handleRefresh = () =>
    {
        fetchData();
    }
    return (
        <div>
            <div style={ {
                padding: '0 5px'
            } }>
                <div style={ {
                    display: 'flex',
                    gap: 5,
                    padding: '10px 0'
                } }>
                    <Button onClick={ handleRefresh } icon={ <SlRefresh /> } />
                    <Button
                        style={ { padding: 5 } }
                        type='primary'
                        onClick={ handleSelectAction }
                        icon={ <PiCursorClickThin /> } />
                    <Select
                        style={ { width: '20%' } }
                        placeholder='Pilih Aksi'
                        onChange={ handleSelectChange }
                        allowClear
                        options={ [
                            { value: 'bayarLahan', label: 'Bayar Lahan' },
                            { value: 'editPembayaran', label: 'Edit Pembayaran' },
                        ] } />
                    <Button onClick={ handleTambahLahan }>
                        Lahan Baru
                    </Button>
                </div>
                <Table
                    bordered
                    columns={ columns }
                    showSorterTooltip={ false }
                    dataSource={ listLahan }
                    rowKey="_id"
                    loading={ loading }
                    size="small"
                    scroll={ { y: 400 } }
                />
            </div>
            <div>
                <Drawer
                    title="Edit Data Lahan"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEditDrawer }
                    visible={ drawerEditDataLahan }
                    width="35%"
                >
                    <EditDataLahan
                        recordId={ editRecordId }
                        onClose={ handleCloseEditDrawer }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Cek Detail Lahan"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseCheckData }
                    visible={ drawerCheckData }
                    width="35%"
                >
                    <DataLahanCek
                        recordId={ editRecordId }
                        onClose={ handleCloseCheckData }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Lahan Baru"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseTambahLahan }
                    visible={ drawerTambahLahan }
                    width="35%"
                >
                    <PenambahanLahan onClose={ handleCloseTambahLahan } fetchData={ fetchData } />
                </Drawer>
                <Drawer
                    title="Bayar Lahan"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseBayarLahan }
                    visible={ drawerBayarLahan }
                    width="35%"
                >
                    <BayarLahan onClose={ handleCloseBayarLahan } fetchData={ fetchData } />
                </Drawer>
                <Drawer
                    title="Edit Pembayaran"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEditByr }
                    visible={ drawerEditPembayaran }
                    width="35%"
                >
                    <EditBayarLahan onClose={ handleCloseEditByr } fetchData={ fetchData } />
                </Drawer>
            </div>
        </div>
    );
};

export default LahanData;
