import React, { useEffect, useState } from 'react';
import { Button, Drawer, Table, message, Input, DatePicker, InputNumber, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { PiCursorClickThin } from "react-icons/pi";
import { CiTrash } from 'react-icons/ci';
import axios from 'axios';
import BayarWartawan from './wartawanBayar';
import TambahWartawan from './wartawanBaru';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import { SlRefresh } from 'react-icons/sl';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' );

const DataWartawan = () =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ data, setData ] = useState( [] );
    const [ loading, setLoading ] = useState( true );
    const [ drawerBayar, setDrawerBayar ] = useState( false );
    const [ searchedColumn, setSearchedColumn ] = useState( '' );
    const [ drawerMediaBaru, setDrawerMediaBaru ] = useState( false );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ filterJenis, setFilterJenis ] = useState( [] );
    const [ filterIdCard, setFilterCardId ] = useState( [] );
    const [ totalBayarMedia, setTotalBayarMedia ] = useState();
    const [ selectedAction, setSelectedAction ] = useState();
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/otherPayment` );
            const data1 = response.data;
            setData( data1 );
            setFilterJenis( getUniqueList( data1 ) );
            setFilterCardId( getUniqueId( data1 ) );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        } finally
        {
            setLoading( false );
        }
    };
    const getUniqueList = ( data ) =>
    {
        const uniqueTypes = [ ...new Set( data.map( item => item.jenis ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const getUniqueId = ( data ) =>
    {
        const filteredData = data.filter( item => item.idCard );
        const uniqueTypes = [ ...new Set( filteredData.map( item => item.idCard ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
        } ).format( value );
    };
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const handleSearch = ( confirm, dataIndex ) =>
    {
        confirm();
        setSearchedColumn( dataIndex );
    };
    const handleReset = ( clearFilters ) =>
    {
        clearFilters();
    };
    const getColumnSearchProps = ( dataIndex, title ) => ( {
        filterDropdown: ( { setSelectedKeys, selectedKeys, confirm, clearFilters } ) => (
            <div style={ { padding: 8 } }>
                <Input
                    placeholder={ `Search ${ title }` }
                    value={ selectedKeys[ 0 ] }
                    onChange={ e => setSelectedKeys( e.target.value ? [ e.target.value ] : [] ) }
                    onPressEnter={ () => handleSearch( selectedKeys, confirm, dataIndex ) }
                    style={ { width: 188, marginBottom: 8, display: 'block' } }
                />
                <Button
                    type="primary"
                    onClick={ () => handleSearch( selectedKeys, confirm, dataIndex ) }
                    icon={ <SearchOutlined /> }
                    size="small"
                    style={ { width: 90, marginRight: 8 } }
                >
                    Search
                </Button>
                <Button onClick={ () => handleReset( clearFilters ) } size="small" style={ { width: 90 } }>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={ { color: filtered ? '#1890ff' : undefined } } />,
        onFilter: ( value, record ) => record[ dataIndex ] ? record[ dataIndex ].toString().toLowerCase().includes( value.toLowerCase() ) : '',
        render: text => searchedColumn === dataIndex ? <span>{ text }</span> : text,
    } );
    const handleMediaBaru = () =>
    {
        setDrawerMediaBaru( true );
    };
    const handleCloseDrawerMediaBaru = () =>
    {
        setDrawerMediaBaru( false );
    };
    const handleBayar = () =>
    {
        setDrawerBayar( true );
    };
    const handleCloseDrawer = () =>
    {
        setDrawerBayar( false );
    };
    const handleMonthChange = ( date ) =>
    {
        setSelectedMonth( date );
    };
    const filtersData = data.filter( ( item ) =>
    {
        if ( selectedMonth )
        {
            return dayjs( item.tanggalKasih, 'DD/MM/YYYY' ).isSame( selectedMonth, 'month' );
        } else
        {
            return true;
        }
    } );
    useEffect( () =>
    {
        if ( selectedMonth )
        {
            const bayarMediaBulanan = filtersData.reduce( ( total, item ) =>
            {
                return total + ( item.jumlahKasih || 0 );
            }, 0 );
            setTotalBayarMedia( bayarMediaBulanan );
        } else
        {
            const totalBayarSemuaMedia = data.reduce( ( total, item ) =>
            {
                return total + ( item.jumlahKasih || 0 );
            }, 0 );
            setTotalBayarMedia( totalBayarSemuaMedia );
        }
    }, [ filtersData, selectedMonth, data ] );
    const handleSelectChange = ( value ) =>
    {
        if ( value )
        {
            setSelectedAction( value );
        } else
        {
            setSelectedAction( null );
        }
    }
    const handleSelectAction = () =>
    {
        if ( selectedAction === 'tambahMedia' )
        {
            handleMediaBaru();
        } if ( selectedAction === 'bayarMedia' )
        {
            handleBayar();
        }
    }
    const handleDelete = async ( recordId ) =>
    {
        try
        {
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/otherPayment/${ recordId }` );
            message.success( 'Berhasil menghapus pembayaran media' );
            fetchData();
        } catch ( error )
        {
            message.error( 'Gagal menghapus pembayaran media' );
        }
    }
    const columns = [
        {
            title: '',
            key: 'no',
            align: 'center',
            render: ( record ) => (
                <Button type='default' onClick={ () => handleDelete( record._id ) }
                    icon={ <CiTrash /> } />
            ),
        },
        {
            title: 'No',
            key: 'no',
            align: 'center',
            render: ( text, record, index ) => index + 1,
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Wartawan</p>,
            dataIndex: 'nama',
            align: 'left',
            width: '40vw',
            ...getColumnSearchProps( 'nama', 'Nama Wartawan' ),
        },
        {
            title: 'Tanggal',
            dataIndex: 'tanggalKasih',
            width: 'auto',
            align: 'center',
        },
        {
            title: 'Jenis',
            dataIndex: 'jenis',
            width: 'auto',
            align: 'center',
            filters: filterJenis,
            onFilter: ( value, record ) => record.jenis.includes( value ),
        },
        {
            title: 'Jumlah',
            dataIndex: 'jumlahKasih',
            width: 'auto',
            align: 'center',
            render: ( value ) => convertToIDR( value ),
        },
        {
            title: 'Id Card',
            dataIndex: 'idCard',
            width: 'auto',
            align: 'center',
            filters: filterIdCard,
            onFilter: ( value, record ) => record.idCard.includes( value ),
        },

    ];
    const handleRefresh = () =>
    {
        fetchData();
    };
    return (
        <div >
            <div style={ {
                padding: '0 5px'
            } }>
                <div style={ {
                    display: 'flex',
                    gap: 5,
                    padding: '10px 0'
                } }>
                    <Button style={ { padding: 5 } } onClick={ handleRefresh } icon={ <SlRefresh /> } />
                    <Button
                        style={ { padding: 5 } }
                        type='primary'
                        onClick={ handleSelectAction }
                        icon={ <PiCursorClickThin /> } />
                    <Select
                        style={ { width: '30%' } }
                        placeholder='Pilih Aksi'
                        onChange={ handleSelectChange }
                        allowClear
                        options={ [
                            { value: 'bayarMedia', label: 'Bayar Media' },
                            { value: 'tambahMedia', label: 'Tambah Media' },
                        ] } />
                    <InputNumber
                        readOnly
                        style={ { width: '100%' } }
                        value={ totalBayarMedia >= 1 && convertToIDR( totalBayarMedia ) }
                        addonBefore={ totalBayarMedia >= 1 && 'Total' }
                        placeholder={ totalBayarMedia < 1 && 'Tidak ada pengeluaran media' } />
                    <DatePicker.MonthPicker
                        style={ { width: '30%' } }
                        onChange={ handleMonthChange }
                        value={ selectedMonth }
                        picker="month"
                        format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                        placeholder='Pilih Bulan'
                    />
                </div>
                <Table
                    bordered
                    columns={ columns }
                    dataSource={ filtersData }
                    loading={ loading }
                    rowKey="_id"
                    pagination={ false }
                    scroll={ { y: 360 } }
                    size="small"
                />
            </div>
            <div>
                <Drawer
                    title="Bayar Media"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseDrawer }
                    visible={ drawerBayar }
                    width="35%"
                >
                    <BayarWartawan
                        onClose={ handleCloseDrawer }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Tambah Media Baru"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseDrawerMediaBaru }
                    visible={ drawerMediaBaru }
                    width="35%"
                >
                    <TambahWartawan onClose={ handleCloseDrawerMediaBaru } fetchData={ fetchData } />
                </Drawer>
            </div>
        </div>
    );
};

export default DataWartawan;
