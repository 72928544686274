import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, message, Spin, InputNumber, Popconfirm } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const { Option } = Select;

const EditBayarLahan = ( { onClose, fetchData } ) =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const [ dataLahan, setDataLahan ] = useState( [] );
    const [ filteredLocation, setFilteredLocation ] = useState( [] );
    const [ lahanTerpilih, setLahanTerpilih ] = useState( [] );
    const [ yearData, setYearData ] = useState( [] );
    const [ paymentId, setPaymentId ] = useState( null )
    const fetchListLahan = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/lahan` );
            setDataLahan( response.data );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal memuat data lahan' );
        } finally
        {
            setLoading( false );
        };
    };
    useEffect( () =>
    {
        fetchListLahan();
    }, [] );
    useEffect( () =>
    {
        setYearData( [] )
    }, [ lahanTerpilih ] );
    useEffect( () =>
    {
        setPaymentId( null );
    }, [ lahanTerpilih, yearData ] );
    const handleDelete = async () =>
    {
        try
        {
            setLoading( true )
            await axiosConfig.delete( `${ apiUrl }/lahan/${ lahanTerpilih._id }/bayarLahan/${ paymentId }` )
        } catch ( error )
        {
            console.error( error );
        } finally { setLoading( false ) }
    }
    const handleLokasiLahan = ( value ) =>
    {
        const filterLokasi = dataLahan.filter( item => item.landLocation === value );
        setFilteredLocation( filterLokasi );
        if ( filterLokasi.length > 0 )
        {
            message.success( `${ filterLokasi.length } lahan ditemukan` );
        } else
        {
            message.error( 'Tidak ada lahan ditemukan' );
        }
        form.resetFields( [
            'landCode',
            'farmerName',
            'bayarLhnKe',
            'metodeByrLhn',
            'jumlahByrLhn',
            'kompensasiLhn',
            'tanggalByrLhn',
            'paymentSet',
            'tahunByr',
        ] )
    };
    const handleKodeLahan = ( value ) =>
    {
        const selectLahan = filteredLocation.find( item => item.landCode === value );
        form.setFieldsValue( {
            farmerName: selectLahan.farmerName,
        } );
        form.resetFields( [
            'bayarLhnKe',
            'metodeByrLhn',
            'jumlahByrLhn',
            'kompensasiLhn',
            'tanggalByrLhn',
            'paymentSet',
            'tahunByr',
        ] );
        setLahanTerpilih( selectLahan );
        if ( selectLahan.landPayment.length > 0 )
        {
            message.success( `${ selectLahan.landPayment.length } Pembayaran` );
        } else
        {
            message.info( 'Tidak ada pembayaran' );
        };
    };
    const handleYearSelect = ( date ) =>
    {
        const selectedYear = lahanTerpilih.landPayment
            .filter( f => dayjs( f.tanggalByrLhn, 'DD/MM/YYYY' ).isSame( date, 'year' ) )
        const sortedYearData = selectedYear.sort( ( a, b ) => a.tanggalByrLhn - b.tanggalByrLhn );
        const listYearData = sortedYearData.map( mp => ( {
            ...mp,
            listPembayaran: `${ mp.tanggalByrLhn } ${ mp.jumlahByrLhn && mp.kompensasiLhn
                ? '( LAHAN & KOMPENSS )'
                : mp.jumlahByrLhn
                    ? '( LAHAN )'
                    : mp.kompensasiLhn
                    && '( KOMPENSASI )' }`
        } ) );
        if ( listYearData.length > 0 )
        {
            message.success( `${ listYearData.length } Pembayaran` );
        } else
        {
            message.info( 'Tidak ada pembayaran' );
        };
        setYearData( listYearData );
        form.resetFields( [
            'bayarLhnKe',
            'metodeByrLhn',
            'jumlahByrLhn',
            'kompensasiLhn',
            'tanggalByrLhn',
            'paymentSet',
        ] );
    }
    const handlePaymentChange = ( value ) =>
    {
        const setPayment = yearData.find( item => item._id === value )
        form.setFieldsValue( {
            jumlahByrLhn: setPayment.jumlahByrLhn,
            metodeByrLhn: setPayment.metodeByrLhn,
            tanggalByrLhn: dayjs( setPayment.tanggalByrLhn, 'DD/MM/YYYY' ),
            kompensasiLhn: setPayment.kompensasiLhn,
            bayarLhnKe: setPayment.bayarLhnKe,
        } )
        if ( value )
        {
            setPaymentId( setPayment._id );
        } else
        {
            setPayment( null )
        }
    }
    const onFinish = ( values ) =>
    {
        const paymentData = {
            jumlahByrLhn: values.jumlahByrLhn,
            metodeByrLhn: values.metodeByrLhn,
            tanggalByrLhn: dayjs( values.tanggalByrLhn ).format( 'DD/MM/YYYY' ),
            kompensasiLhn: values.kompensasiLhn,
        };
        try
        {
            setLoading( true );
            axiosConfig.put( `${ apiUrl }/lahan/${ lahanTerpilih._id }/bayarLahan/${ paymentId }`, paymentData );
            message.success( 'Pembayaran lahan berhasil diperbarui' );
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            message.error( 'Gagal memperbarui pembayaran' );
        } finally
        {
            setLoading( false );
        };
    };
    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        label="Lokasi Lahan"
                        name="landLocation"
                        rules={ [ { required: true, message: 'Lokasi diperlukan!' } ] }
                    >
                        <Select
                            placeholder="Pilih Lokasi Lahan"
                            onChange={ handleLokasiLahan }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG', label: 'TANJUNG' },
                            ] }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="landCode"
                        label="Kode Lahan Kavling"
                        rules={ [ { required: true, message: 'Kode lahan diperlukan!' } ] }
                    >
                        <Select
                            placeholder="Pilih Kode Lahan"
                            onChange={ handleKodeLahan }
                        >
                            { filteredLocation.map( ( lahan ) => (
                                <Option key={ lahan.landCode } value={ lahan.landCode }>
                                    { lahan.landCode }
                                </Option>
                            )
                            ) }
                        </Select>
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name="farmerName"
                        label="Nama Petani"
                    >
                        <Input placeholder="Nama Petani" readOnly />
                    </Form.Item>
                    <Form.Item
                        label='Pilih Tahun Pembayaran'
                        style={ { width: '100%' } }
                        name='tahunByr'
                    >
                        <DatePicker.YearPicker
                            allowClear={ false }
                            placeholder='Pilih Tahun'
                            style={ { width: '100%' } }
                            onChange={ handleYearSelect } />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        label='Pilih Pembayaran'
                        style={ { width: '100%' } }
                        name='paymentSet'
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Pembayaran'
                            onChange={ handlePaymentChange }
                            options={ yearData.map( yd => (
                                { value: yd._id, label: yd.listPembayaran }
                            ) ) } />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="bayarLhnKe"
                        label="Pembayaran Lahan"
                    >
                        <InputNumber
                            addonBefore='Ke' readOnly
                            placeholder='Bayar Lahan Ke'
                            style={ { width: '100%' } } />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name="metodeByrLhn"
                        label="Metode Pembayaran"
                        rules={ [ { required: true, message: 'Metode Pembayaran diperlukan!' } ] }
                    >
                        <Select placeholder="Pilih Metode">
                            <Option value="TUNAI">TUNAI</Option>
                            <Option value="BRI">BRI</Option>
                            <Option value="BNI">BNI</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jumlahByrLhn"
                        label="Jumlah Pembayaran"
                    >
                        <InputNumber
                            addonBefore='Rp'
                            placeholder="Masukkan Jumlah Pembayaran"
                            style={ { width: '100%' } }
                        />
                    </Form.Item>
                </div>
                <div className="payments-flex">
                    <Form.Item
                        style={ { width: '100%' } }
                        name="kompensasiLhn"
                        label="Kompensasi Pembayaran"
                    >
                        <InputNumber
                            addonBefore='Rp'
                            placeholder="Masukkan Kompensasi Pembayaran"
                            style={ { width: '100%' } }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="tanggalByrLhn"
                        label="Tanggal Pembayaran"
                        rules={ [ { required: true, message: 'Tanggal Pembayaran diperlukan!' } ] }
                    >
                        <DatePicker placeholder='Pilih Tanggal' style={ { width: '100%' } } format="DD/MM/YYYY" />
                    </Form.Item>
                </div>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Button type="primary" htmlType="submit">
                        Update Pembayaran
                    </Button>
                    { paymentId !== null && (
                        <Popconfirm
                            title="Apakah kamu yakin menghapus data ini?"
                            onConfirm={ handleDelete }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger type='default' >
                                Hapus
                            </Button>
                        </Popconfirm>
                    ) }
                </div>
            </Form>
        </Spin>
    );
};

export default EditBayarLahan;
