import React, { useEffect, useState } from 'react';
import { Button, Drawer, Table, message, DatePicker, InputNumber } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import BayarMaterial from './bayarMaterial';// Ubah nama file jika perlu
import EditBayarMaterial from './editMaterial';
import { CiEdit } from 'react-icons/ci';
import 'dayjs/locale/id';
import { SlRefresh } from 'react-icons/sl';
dayjs.locale( 'id' );
const Material = () =>
{
    const [ data, setData ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ drawerAdd, setDrawerItemAdd ] = useState( false );
    const [ drawerEdit, setDrawerItemEdit ] = useState( false );
    const [ editRecordId, setEditRecordId ] = useState( null );
    const [ paidMethodFilters, setPaidMethodFilters ] = useState( [] );
    const [ filterToko, setFilterToko ] = useState( [] );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ materialsPayment, setMaterialsPayment ] = useState();
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/materials` );
            const updatedData = response.data;
            setData( updatedData );
            setPaidMethodFilters( getUniqueMethodTypes( updatedData ) );
            setFilterToko( getUniqueFilterToko( updatedData ) );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        } finally
        {
            setLoading( false );
        }
    };
    const getUniqueMethodTypes = ( data ) =>
    {
        const uniqueTypes = [ ...new Set( data.map( item => item.metodeBayarMaterial ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const getUniqueFilterToko = ( data ) =>
    {
        const uniqueTypes = [ ...new Set( data.map( item => item.namaToko ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const handleMonthChange = ( date ) =>
    {
        setSelectedMonth( date );
    };
    const filterDateData = data.filter( ( item ) =>
    {
        if ( selectedMonth )
        {
            return dayjs( item.tanggalBeliMaterial, 'DD/MM/YYYY' ).isSame( selectedMonth, 'month' );
        } else
        {
            return true;
        }
    } );
    useEffect( () =>
    {
        if ( selectedMonth )
        {
            const monthlyMaterials = filterDateData.reduce( ( total, item ) =>
            {
                return total + ( item.hargaMaterial );
            }, 0 );
            setMaterialsPayment( monthlyMaterials );
        } else
        {
            const totalMaterialsPayment = data.reduce( ( total, item ) =>
            {
                return total + ( item.hargaMaterial );
            }, 0 );
            setMaterialsPayment( totalMaterialsPayment );
        }
    }, [ filterDateData, selectedMonth, data ] );
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
        } ).format( value );
    };
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const columns = [
        {
            title: 'Aksi',
            key: 'action',
            width: '7%',
            align: 'center',
            render: ( text, record ) => (
                <Button
                    type='default'
                    onClick={ () => handleEditItem( record._id ) }
                    icon={ <CiEdit /> } />
            ),
        },
        {
            title: 'No',
            key: 'no',
            align: 'center',
            render: ( text, record, index ) => index + 1,
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Material</p>,
            dataIndex: 'namaMaterial',
            align: 'left',
            width: '20vw',
        },
        {
            title: 'Tanggal Bayar',
            dataIndex: 'tanggalBeliMaterial',
            align: 'center',
            width: 'auto',
        },
        {
            title: 'Rincian',
            width: 'auto',
            align: 'center',
            children: [
                {
                    title: 'Harga',
                    dataIndex: 'hargaSatuan',
                    width: 'auto',
                    align: 'center',
                },
                {
                    title: 'Unit',
                    dataIndex: 'unitMaterial',
                    width: 'auto',
                    align: 'center',
                },
                {
                    title: 'Satuan',
                    dataIndex: 'satuanMaterial',
                    width: 'auto',
                    align: 'center',
                },
                {
                    title: 'Total',
                    dataIndex: 'hargaMaterial',
                    align: 'center',
                    width: 'auto',
                },
            ]
        },
        {
            title: 'Pembayaran',
            dataIndex: 'metodeBayarMaterial',
            width: 'auto',
            align: 'center',
            filters: paidMethodFilters,
            onFilter: ( value, record ) => record.metodeBayarMaterial.includes( value ),
        },
        {
            title: 'Galangan',
            dataIndex: 'namaToko',
            width: 'auto',
            align: 'center',
            filters: filterToko,
            onFilter: ( value, record ) => record.namaToko.includes( value ),
        },
    ];
    const handleEditItem = ( recordId ) =>
    {
        setEditRecordId( recordId );
        setDrawerItemEdit( true );
    };
    const handleAddItem = () =>
    {
        setDrawerItemAdd( true );
    };
    const handleCloseDrawer = () =>
    {
        setDrawerItemAdd( false );
    };

    const handleCloseEditDrawer = () =>
    {
        setDrawerItemEdit( false );
        setEditRecordId( null );
    };
    const handleRefresh = () =>
    {
        fetchData();
    };
    return (
        <div>
            <div style={ {
                padding: '0 5px'
            } }>
                <div style={ {
                    display: 'flex',
                    gap: 5,
                    padding: '10px 0'
                } }>
                    <Button style={ { padding: 5 } } onClick={ handleRefresh } icon={ <SlRefresh /> } />
                    <Button
                        onClick={ handleAddItem }>Bayar Material</Button>
                    <InputNumber
                        readOnly
                        style={ { width: '100%' } }
                        value={ materialsPayment >= 1 && convertToIDR( materialsPayment ) }
                        addonBefore={ materialsPayment >= 1 && 'Total' }
                        placeholder={ materialsPayment < 1 && 'Tidak ada pembayaran material' } />
                    <DatePicker.MonthPicker
                        style={ { width: '17%' } }
                        placeholder='Pilih Bulan'
                        onChange={ handleMonthChange }
                        value={ selectedMonth }
                        picker='month'
                        allowClear
                        format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                    />
                </div>
                <Table
                    bordered
                    showSorterTooltip={ false }
                    columns={ columns }
                    dataSource={ filterDateData }
                    rowKey="_id"
                    loading={ loading }
                    pagination={ { pageSize: 10 } }
                    size='small'
                />
            </div>
            <div>
                <Drawer
                    title="Bayar Material"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseDrawer }
                    visible={ drawerAdd }
                    width="50%"
                >
                    <BayarMaterial
                        onClose={ handleCloseDrawer }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Edit Data"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEditDrawer }
                    visible={ drawerEdit }
                    width="50%"
                >
                    <EditBayarMaterial
                        recordId={ editRecordId }
                        onClose={ handleCloseEditDrawer }
                        fetchData={ fetchData }
                    />
                </Drawer>
            </div>
        </div>
    );
};

export default Material;
