import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, message, Spin, InputNumber, Modal } from 'antd';
import axios from 'axios';
import '../payments.css';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import CreateAxiosInstance from '../../../../Authentication/contexts/axiosConfig';
dayjs.locale( 'id' );

const { Option } = Select;

const BayarDP = ( { onClose, fetchData } ) =>
{
    const [ form ] = Form.useForm(); const axiosConfig = CreateAxiosInstance();
    const [ customers, setCustomers ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ plotBlockList, setPlotBlockList ] = useState( [] );
    const [ plotLocations, setPlotLocations ] = useState( [] );
    const [ customerId, setCustomerId ] = useState( null );
    const fetchCustomers = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/customers` );
            const data = response.data;
            const updateData = data.filter( customer =>
            {
                const totalDP = customer.pembayaranDP.reduce( ( total, dp ) => total + ( dp.jumlahByrDp || 0 ), 0 ) + customer.jumlahUTJ;
                return totalDP < customer.uangMukaKavling;
            } );
            setCustomers( updateData );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal memuat data pelanggan' );
        } finally
        {
            setLoading( false );
        }
    };
    useEffect( () =>
    {
        fetchCustomers();
    }, [] )
    const handleLocationChange = ( value ) =>
    {
        const lokasiKav = customers.filter( item => item.lokasiKavling === value );
        setPlotLocations( lokasiKav );
        if ( lokasiKav.length > 0 )
        {
            message.success( `${ lokasiKav.length } blok ditemukan` );
        } else
        {
            setPlotLocations( [] );
        }
        form.resetFields( [
            'blokKavling',
            'noKavling',
            'namaCustomer',
            'dpKe',
            'jumlahByrDp',
            'metodeBayarDp',
            'tanggalByrDp',
        ] );
    };
    const handleBlokChange = ( blok ) =>
    {
        if ( blok )
        {
            const blokKav = plotLocations.filter( item => item.blokKavling === blok );
            setPlotBlockList( blokKav );
            if ( blokKav.length )
            {
                message.success( `${ blokKav.length } kavling ditemukan` );
            } else
            {
                message.error( 'Tidak ada kavling ditemukan' );
            }
        } else
        {
            setPlotBlockList( [] );
        }
        form.resetFields( [
            'noKavling',
            'namaCustomer',
            'dpKe',
            'jumlahByrDp',
            'metodeBayarDp',
            'tanggalByrDp',
        ] );
    };
    useEffect( () =>
    {
        setPlotBlockList( [] )
    }, [ plotLocations ] );
    const handleKavlingNumber = ( number ) =>
    {
        if ( number )
        {
            const noKav = plotBlockList.find( ( item ) => item.noKavling === number );
            const dpData = noKav.pembayaranDP;
            form.setFieldsValue( {
                namaCustomer: noKav.namaCustomer,
                dpKe: dpData.length + 1,
            } );
            setCustomerId( noKav._id );
        } else
        {
            setCustomerId( null );
        }
    };
    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    const onFinish = async ( values ) =>
    {
        const dataPayment = {
            ...values,
            dpKe: values.dpKe,
            metodeBayarDp: values.metodeBayarDp,
            jumlahByrDp: values.jumlahByrDp,
            tanggalByrDp: dayjs( values.tanggalByrDp ).format( 'DD/MM/YYYY' ),
        };
        const paymentValidate = () =>
        {
            Modal.confirm( {
                title: 'Apakah data pembayaran sudah benar?',
                content: (
                    <>
                        <p>Jumlah Bayar: { convertToIDR( values.jumlahByrDp ) }</p>
                        <p>Tanggal Bayar: { dayjs( values.tanggalByrDp ).format( 'dddd, DD MMMM YYYY' ) }</p>
                        <p>Metode Bayar: { values.metodeBayarDp }</p>
                        <p>Dp Ke: { values.dpKe }</p>
                    </>
                ),
                onOk: async () =>
                {
                    try
                    {
                        setLoading( true );
                        await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/customer/${ customerId }/dp`, dataPayment );
                        message.success( 'Pembayaran berhasil' );
                        fetchCustomers();
                        form.resetFields();
                        onClose();
                        fetchData();
                    } catch ( error )
                    {
                        console.error( 'Gagal menambahkan pembayaran', error );
                    }
                    finally
                    {
                        setLoading( false );
                    }
                },
                onCancel ()
                {
                    message.info( 'Pembayaran dibatalkan' );
                },
            } );
        };
        paymentValidate();
    };
    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="lokasiKavling"
                        label="Lokasi Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Lokasi Kavling"
                            onChange={ handleLocationChange }
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="blokKavling"
                        label="Blok Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Blok Kavling"
                            onChange={ handleBlokChange }
                        >
                            {
                                [ ...new Set( plotLocations.map( blok => blok.blokKavling ) ) ]
                                    .map( ( blokKavling, index ) => (
                                        <Option key={ index } value={ blokKavling }>
                                            { blokKavling }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="noKavling"
                        label="No Kavling"
                        rules={ [ { required: true, message: 'Opsi diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih No Kavling"
                            onChange={ handleKavlingNumber }
                            options={ plotBlockList.map( li => (
                                { value: li.noKavling, label: li.noKavling }
                            ) ) }
                        />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="namaCustomer"
                        label="Nama Pembeli"
                    >
                        <Input placeholder="Nama Pembeli" readOnly />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="dpKe"
                        label="Pembayaran Ke"
                        rules={ [ { required: true, message: 'Pembayaran ke diperlukan' } ] }
                    >
                        <InputNumber addonBefore='Ke' style={ { width: '100%' } } readOnly placeholder="Pembayaran ke" />
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="jumlahByrDp"
                        label="Jumlah Bayar"
                        rules={ [ { required: true, message: 'Jumlah pembayaran diperlukan' } ] }
                    >
                        <InputNumber addonBefore='Rp' style={ { width: '100%' } } placeholder='Masukkan Jumlah Pembayaran' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="metodeBayarDp"
                        label="Metode Pembayaran"
                        rules={ [ { required: true, message: 'Metode pembayaran diperlukan' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Metode'
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                                { value: 'MANDIRI', label: 'MANDIRI' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    style={ { width: '100%' } }
                    name="tanggalByrDp"
                    label="Tanggal Pembayaran"
                    rules={ [ { required: true, message: 'Pilih tanggal' } ] }
                >
                    <DatePicker style={ { width: '100%' } } format="DD/MM/YYYY" placeholder='Pilih Tanggal' />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    Bayar
                </Button>
            </Form>
        </Spin>
    );
};

export default BayarDP;
