import React, { useState } from 'react';
import { Layout, Menu, Modal, message } from 'antd';
import { IoIosList } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { BsCashCoin } from 'react-icons/bs';
import './Sidebar.css';
import { BsClockHistory } from "react-icons/bs";
import { PiCashRegisterThin, PiPersonSimpleCircleThin } from 'react-icons/pi';
import { GrTransaction } from "react-icons/gr";
import { LiaStickyNoteSolid } from 'react-icons/lia';
import { TbMoneybag } from "react-icons/tb";
import { CiMoneyCheck1 } from "react-icons/ci";
import { useAuth } from '../../Authentication/contexts/authContext';
import { SolutionOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import Logo from './LOGO.png';

const { Sider } = Layout;
const { SubMenu } = Menu;

const DefaultSidebar = () =>
{
    const [ collapsed, setCollapsed ] = useState( false );
    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleLogout = () =>
    {
        Modal.confirm( {
            title: 'Konfirmasi Logout',
            content: 'Apakah Anda yakin ingin logout?',
            okText: 'Ya',
            cancelText: 'Tidak',
            onOk: () =>
            {
                logout();
                navigate( '/' );
            },
            onCancel: () =>
            {
                message.info( 'Logout dibatalkan' );
            }
        } );
    };

    const handleDashboard = () =>
    {
        navigate( '/' );
    };

    const handleDataFinanceOut = () =>
    {
        navigate( '/pengeluaran' );
    };

    const handleMatsData = () =>
    {
        navigate( '/material' );
    };

    const handleLahanData = () =>
    {
        navigate( '/lahan-kavling' );
    };

    const handleKomisiData = () =>
    {
        navigate( '/komisi-penjualan' );
    };

    const handleWartawanData = () =>
    {
        navigate( '/data-wartawan' );
    };

    const handleTukagnGajiData = () =>
    {
        navigate( '/data-gajiTukang' );
    };

    const handleStatisticData = () =>
    {
        navigate( '/statistic-finance' );
    };

    const handleDataCust = () =>
    {
        navigate( '/data-customers' );
    };

    const handleDataAngsuran = () =>
    {
        navigate( '/data-angsuran' );
    };

    const handleDataUangMuka = () =>
    {
        navigate( '/data-uangmuka' );
    };

    const handlePiutangCust = () =>
    {
        navigate( '/piutang-cust' );
    };

    const handleEmployeeSalary = () =>
    {
        navigate( '/employee-salary' );
    };

    const handleOtherPayment = () =>
    {
        navigate( '/unexpected-expenses' );
    };

    const handleDaftarPinjaman = () =>
    {
        navigate( '/daftar-pinjaman' );
    };

    const handleDataPinjaman = () =>
    {
        navigate( '/data-pinjamanKasbon' );
    };

    const handleDataPembayaran = () =>
    {
        navigate( '/data-pembayaranKasbon' );
    };

    const handleListPlot = () =>
    {
        navigate( '/data-kavling' );
    };

    const handleBayarKBRumah = () =>
    {
        navigate( '/bayarlampudanair' );
    };

    const handleDataFinanceIn = () =>
    {
        navigate( '/pemasukan' );
    };

    const handleAdditionalFinanceIn = () =>
    {
        navigate( '/tambahan-pemasukan' );
    };

    const handleCashFlow = () =>
    {
        navigate( '/daily-cash-flow' );
    };

    const handleCashPaymentData = () =>
    {
        navigate( '/data-cash-tempo' );
    };
    const handleDataDenda = () =>
    {
        navigate( '/data-denda' );
    };
    const handleLogsFiles = () =>
    {
        navigate( '/data-logs' );
    };
    return (
        <div>
            <Sider width={ 200 } className='siders' trigger={ null } collapsible collapsed={ collapsed }>
                <Menu mode='inline' style={ { height: '100%', borderRight: 0 } }>
                    <div className='sidebar-logo' onClick={ () => setCollapsed( !collapsed ) }>
                        <img src={ Logo } alt='' className={ !collapsed ? 'sidebar-icon' : 'sidebar-icons' } />
                    </div>
                    <Menu.Item onClick={ handleDashboard } key='1' icon={ <TeamOutlined /> }>Dashboard</Menu.Item>
                    <Menu.Item key='2' onClick={ handleListPlot } icon={ <IoIosList /> }>Daftar Kavling</Menu.Item>
                    <SubMenu key='sub2' icon={ <UserOutlined /> } title='Data Customer'>
                        <Menu.Item onClick={ handleDataCust } key='3'>Customer</Menu.Item>
                        <Menu.Item key='4' onClick={ handleDataAngsuran }>Angsuran</Menu.Item>
                        <Menu.Item key='26' onClick={ handleDataDenda }>Denda</Menu.Item>
                        <Menu.Item key='5' onClick={ handleDataUangMuka }>Uang Muka</Menu.Item>
                        <Menu.Item key='6' onClick={ handleBayarKBRumah }>Lampu & Air</Menu.Item>
                        <Menu.Item key='7' onClick={ handleCashPaymentData }>Cash / Tempo</Menu.Item>
                        <Menu.Item key='8' onClick={ handlePiutangCust }>Piutang</Menu.Item>
                    </SubMenu>
                    <SubMenu key='sub1' icon={ <SolutionOutlined /> } title='Data Pemasukan'>
                        <Menu.Item onClick={ handleDataFinanceIn } key='9' icon={ <PiCashRegisterThin /> }>Rekab Pemasukan</Menu.Item>
                        <Menu.Item icon={ <BsCashCoin /> } onClick={ handleAdditionalFinanceIn } key='10'>Pemasukan Tambahan</Menu.Item>
                    </SubMenu>
                    <SubMenu key='sub3' icon={ <LiaStickyNoteSolid /> } title='Data Pengeluaran'>
                        <Menu.Item onClick={ handleDataFinanceOut } key='11' icon={ <PiCashRegisterThin /> }>Rekab Pengeluaran</Menu.Item>
                        <Menu.Item onClick={ handleStatisticData } key='12' icon={ <CiMoneyCheck1 /> }>Statistic</Menu.Item>
                        <SubMenu key='sub4' icon={ <LiaStickyNoteSolid /> } title='Pengeluaran'>
                            <Menu.Item onClick={ handleEmployeeSalary } key='13'>Gaji Karyawan</Menu.Item>
                            <Menu.Item onClick={ handleMatsData } key='14'>Material</Menu.Item>
                            <Menu.Item onClick={ handleKomisiData } key='15'>Komisi</Menu.Item>
                            <Menu.Item onClick={ handleTukagnGajiData } key='16'>Gaji Tukang</Menu.Item>
                            <Menu.Item onClick={ handleLahanData } key='17'>Lahan Kavling</Menu.Item>
                            <Menu.Item onClick={ handleWartawanData } key='18'>Media PERS</Menu.Item>
                            <Menu.Item onClick={ handleOtherPayment } key='19'>Lain-Lain</Menu.Item>
                        </SubMenu>
                    </SubMenu>
                    <SubMenu key='sub5' icon={ <TbMoneybag /> } title='Pinjaman'>
                        <Menu.Item onClick={ handleDaftarPinjaman } key='20'>Daftar Pinjaman</Menu.Item>
                        <Menu.Item onClick={ handleDataPinjaman } key='21'>Data Pinjaman</Menu.Item>
                        <Menu.Item onClick={ handleDataPembayaran } key='22'>Data Pembayaran</Menu.Item>
                    </SubMenu>
                    <Menu.Item key='23' onClick={ handleCashFlow } icon={ <GrTransaction /> }>Arus Kas</Menu.Item>
                    <SubMenu key='sub6' icon={ <PiPersonSimpleCircleThin /> } title='Account'>
                        <Menu.Item key='24' onClick={ handleLogsFiles } icon={ <BsClockHistory /> } >Log Data</Menu.Item>
                        <Menu.Item key='25' onClick={ handleLogout }>Log Out</Menu.Item>
                    </SubMenu>
                </Menu>
            </Sider>
        </div>
    );
};

export default DefaultSidebar;
