import { useState } from 'react';
import { useAuth } from '../contexts/authContext';
import { message } from 'antd';

const useLogin = () =>
{
    const { login } = useAuth();
    const [ error, setError ] = useState( null );
    const [ loading, setLoading ] = useState( false );

    const loginUser = async ( values ) =>
    {
        try
        {
            setError( null );
            setLoading( true );
            const res = await fetch( `${ process.env.REACT_APP_LOCAL_API }/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify( values ),
            } );
            const data = await res.json();
            if ( res.status === 200 )
            {
                if ( data.token && data.user )
                {
                    message.success( 'Berhasil Login' );
                    login( data.token, data.user );
                } else
                {
                    throw new Error( "Data pengguna tidak lengkap dalam respons." );
                }
            } else
            {
                setError( data.message );
                message.error( data.message );
            }
        } catch ( error )
        {
            setError( error.message );
            message.error( error.message );
        } finally
        {
            setLoading( false );
        }
    };

    return { loading, error, loginUser };
};

export default useLogin;
