import React from 'react';
import { Navigate } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { useAuth } from '../contexts/authContext';
import DefaultSidebar from '../../components/Function/SidebarDefault';
import AlarmReminder from '../../components/Function/alarm';

const { Content } = Layout;

const ProtectedRouteWithLayout = ( { children } ) =>
{
    const { isAuthenticated, isLoading } = useAuth();
    if ( isLoading )
    {
        return (
            <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' } }>
                <Spin size="large" />
            </div>
        );
    }
    return isAuthenticated ? (
        <Layout style={ { backgroundColor: 'white' } }>
            <div className='barside'>
                <DefaultSidebar />
            </div>
            <AlarmReminder />
            <Content>
                { children }
            </Content>
        </Layout>
    ) : <Navigate to="/unauthorized" />;
};

export default ProtectedRouteWithLayout;
