import React, { useState } from 'react';
import { useAuth } from '../contexts/authContext';
import useLogin from '../hooks/useLogin';
import { Alert, Button, Card, Form, Input, Spin, Typography } from 'antd';
import { Navigate } from 'react-router-dom';
import './styleauth.css';

const LoginAuth = () =>
{
    const { loading, loginUser } = useLogin();
    const { isAuthenticated } = useAuth();
    const [ errorMessage, setErrorMessage ] = useState( '' );

    const handleLogin = async ( values ) =>
    {
        try
        {
            await loginUser( values );
        } catch ( error )
        {
            setErrorMessage( error.message );
        }
    };

    if ( isAuthenticated )
    {
        return <Navigate to="/homepage" />;
    }

    return (
        <div className='auth-form'>
            <Card className='auth-container'>
                <div className='auth-head'>
                    <Typography.Text className='auth-title'>
                        Login
                    </Typography.Text>
                    <Typography.Text style={ { fontStyle: 'italic' } }>
                        Fortuna audaces iuvat
                    </Typography.Text>
                </div>
                <Form layout='vertical' onFinish={ handleLogin } autoComplete='off'>
                    <Form.Item
                        label={ <span className='auth-label'>Email</span> }
                        name='email'
                        rules={ [
                            {
                                required: true,
                                message: 'Masukkan Email Akun Anda!',
                            },
                            {
                                type: 'email',
                                message: 'Email Tidak Valid!',
                            },
                        ] }
                    >
                        <Input size='large' placeholder='Masukkan Email Anda' />
                    </Form.Item>

                    <Form.Item
                        label={ <span className='auth-label'>Password</span> }
                        name='password'
                        rules={ [
                            {
                                required: true,
                                message: 'Masukkan Password Akun Anda!',
                            },
                        ] }
                    >
                        <Input.Password size='large' placeholder='Masukkan Password Anda' />
                    </Form.Item>

                    { errorMessage && (
                        <Alert
                            description={ errorMessage }
                            type='error'
                            showIcon
                            closable
                            className='auth-alert'
                        />
                    ) }

                    <Form.Item>
                        <Button
                            type={ `${ loading ? '' : 'primary' }` }
                            size='large'
                            htmlType='submit'
                            className='auth-button'
                        >
                            { loading ? <Spin /> : 'Sign In' }
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default LoginAuth;
