import React, { useState, useEffect } from 'react';
import { Form, Input, DatePicker, InputNumber, Select, Button, message, Spin } from 'antd';
import axios from 'axios';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const BayarMaterial = ( { fetchData, onClose } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ calculatePaidType, setCalculatePaidType ] = useState( '' );
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const onFinish = async ( values ) =>
    {
        let finalValues = { ...values };

        if ( calculatePaidType === 'auto' )
        {
            const hargaSatuan = form.getFieldValue( 'hargaSatuan' );
            const unitMaterial = form.getFieldValue( 'unitMaterial' );
            if ( hargaSatuan && unitMaterial )
            {
                finalValues.hargaMaterial = hargaSatuan * unitMaterial;
            }
        }

        const formattedValues = {
            ...finalValues,
            tanggalBeliMaterial: finalValues.tanggalBeliMaterial.format( 'DD/MM/YYYY' ),
            paidType: 'MATERIAL',
        };

        try
        {
            setLoading( true );
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/materials`, formattedValues );
            message.success( 'Data Berhasil Ditambahkan' );
            fetchData();
            onClose();
        } catch ( error )
        {
            message.error( 'Gagal menambahkan data' );
        } finally
        {
            setLoading( false );
        }
    };
    const handleCalculatePaidTypeChange = ( value ) =>
    {
        setCalculatePaidType( value );
        if ( value !== 'auto' )
        {
            form.setFieldsValue( { hargaMaterial: null } );
        }
    };
    useEffect( () =>
    {
        const updateHargaMaterial = () =>
        {
            if ( calculatePaidType === 'auto' )
            {
                const hargaSatuan = form.getFieldValue( 'hargaSatuan' );
                const unitMaterial = form.getFieldValue( 'unitMaterial' );
                if ( hargaSatuan && unitMaterial )
                {
                    const hargaMaterial = hargaSatuan * unitMaterial;
                    form.setFieldsValue( { hargaMaterial } );
                }
            }
        };

        updateHargaMaterial();
    }, [ calculatePaidType, form ] );

    return (
        <Spin spinning={ loading }>
            <Form layout='vertical' form={ form } onFinish={ onFinish }>
                <div className='material-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='namaToko'
                        label='Nama Toko Galangan'
                        normalize={ ( value ) => value && value.toUpperCase() }
                    >
                        <Input placeholder='Masukkan Nama Toko' />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='namaMaterial'
                        label='Nama Material'
                        rules={ [ { required: true, message: 'Nama material diperlukan!' } ] }
                        normalize={ ( value ) => value && value.toUpperCase() }
                    >
                        <Input placeholder='Masukkan Nama Item' />
                    </Form.Item>
                </div>
                <div className='material-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        label='Jenis Kalkulasi'
                        name='calculatePaidType'
                        rules={ [ { required: true, message: 'Opsi diperlukan!' } ] }
                    >
                        <Select
                            placeholder='Pilih Jenis Kalkulasi'
                            onChange={ handleCalculatePaidTypeChange }
                            options={ [
                                { value: 'auto', label: 'Auto' },
                                { value: 'custom', label: 'Kustom' },
                            ] }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='hargaMaterial'
                        label='Jumlah Bayar'
                        rules={ [
                            calculatePaidType !== 'auto' ? { required: true, message: 'Jumlah bayar diperlukan!' } : { required: false }
                        ] }
                    >
                        <InputNumber
                            addonBefore='Rp'
                            disabled={ calculatePaidType === 'auto' }
                            style={ { width: '100%' } }
                        />
                    </Form.Item>
                </div>
                <div className='material-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='hargaSatuan'
                        label='Harga Satuan'
                        rules={ [
                            calculatePaidType !== 'custom' ? { required: true, message: 'Harga satuan diperlukan!' } : { required: false }
                        ] }
                    >
                        <InputNumber
                            disabled={ calculatePaidType === 'custom' }
                            addonBefore='Rp'
                            style={ { width: '100%' } }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='satuanMaterial'
                        label='Jenis Satuan'
                        rules={ [ { required: true, message: 'Satuan diperlukan!' } ] }
                    >
                        <Input placeholder='Masukkan Jenis Satuan'
                        />
                    </Form.Item>
                </div>
                <div className='material-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='unitMaterial'
                        label='Jumlah Unit'
                        rules={ [
                            calculatePaidType !== 'custom' ? { required: true, message: 'Jumlah unit diperlukan!' } : { required: false }
                        ] }
                    >
                        <InputNumber
                            addonAfter='Pcs'
                            disabled={ calculatePaidType === 'custom' }
                            placeholder='Masukkan Jumlah Unit'
                            style={ { width: '100%' } }
                        />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name='metodeBayarMaterial'
                        label='Metode Bayar'
                        rules={ [ { required: true, message: 'Metode bayar diperlukan!' } ] }
                    >
                        <Select
                            placeholder='Pilih Metode Bayar'
                            style={ { width: '100%' } }
                            options={ [
                                { value: 'TUNAI', label: 'TUNAI' },
                                { value: 'BRI', label: 'BRI' },
                                { value: 'BNI', label: 'BNI' },
                            ] }
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    style={ { width: '100%' } }
                    name='tanggalBeliMaterial'
                    label='Tanggal Bayar'
                    rules={ [ { required: true, message: 'Tanggal bayar diperlukan' } ] }
                >
                    <DatePicker
                        placeholder='Pilih Tanggal'
                        format='DD/MM/YYYY'
                        style={ { width: '100%' } }
                    />
                </Form.Item>
                <Button type='primary' htmlType='submit'>
                    Tambahkan
                </Button>
            </Form>
        </Spin>
    );
};

export default BayarMaterial;
