import React, { useState, useEffect } from 'react';
import { Form, Button, Select, DatePicker, message, Spin, InputNumber, Input, Popconfirm } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const EditEmployeeSalary = ( { onClose, fetchData, salaryId, employeeId } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    useEffect( () =>
    {
        const fetchEmployeeData = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/employeeList/${ employeeId }` );
                const employeeData = response.data;
                const salaryData = employeeData.dataSalary.find( item => salaryId.includes( item._id ) );
                form.setFieldsValue( {
                    employeeName: employeeData.employeeName,
                    salary: salaryData.salary,
                    additionalSalary: salaryData.additionalSalary,
                    dateSalary: dayjs( salaryData.dateSalary, 'DD/MM/YYYY' ),
                    salaryMethod: salaryData.salaryMethod,
                } );
            } catch ( error )
            {
                message.error( 'Gagal memuat data dari server' );
                onClose();
            } finally
            {
                setLoading( false );
            }
        };
        if ( employeeId && salaryId )
        {
            fetchEmployeeData();
        }
    }, [ salaryId, employeeId, form, onClose ] );
    const handleDelete = async () =>
    {
        try
        {
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/employee/${ employeeId }/salary/${ salaryId }` );
            onClose();
            message.success( 'Berhasil menghapus gaji' );
            fetchData()
        } catch ( error )
        {
            message.error( 'Gagal menghapus gaji' );
        }
    }
    const onFinish = async ( values ) =>
    {
        const salaryData = {
            salary: values.salary,
            additionalSalary: values.additionalSalary,
            dateSalary: dayjs( values.dateSalary ).format( 'DD/MM/YYYY' ),
            salaryMethod: values.salaryMethod,
        };
        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/employee/${ employeeId }/salary/${ salaryId }`, salaryData );
            message.success( 'Pembaruan data gaji karyawan berhasil' );
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            message.error( 'Gagal memperbarui data gaji' );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <Form.Item
                    style={ { width: '100%' } }
                    name='employeeName'
                    label="Pilih Karyawan"
                >
                    <Input readOnly />
                </Form.Item>
                <Form.Item
                    style={ { width: '100%' } }
                    name="salary"
                    label="Gaji Pokok"
                    rules={ [ { required: true, message: 'Gaji pokok diperlukan!' } ] }
                >
                    <InputNumber
                        addonBefore='Rp'
                        style={ { width: '100%' } }
                        placeholder='Masukkan Gaji Pokok'
                    />
                </Form.Item>
                <Form.Item
                    style={ { width: '100%' } }
                    name="additionalSalary"
                    label="Tambahan Gaji"
                    tooltip='Tidak Wajib'
                >
                    <InputNumber
                        addonBefore='Rp'
                        style={ { width: '100%' } }
                        placeholder='Masukkan Tambahan Gaji'
                    />
                </Form.Item>
                <Form.Item
                    style={ { width: '100%' } }
                    name="salaryMethod"
                    label="Metode Bayar"
                    rules={ [ { required: true, message: 'Metode bayar diperlukan!' } ] }
                >
                    <Select
                        style={ { width: '100%' } }
                        placeholder='Pilih Metode Bayar'
                        options={ [
                            { value: 'TUNAI', label: 'TUNAI' },
                            { value: 'BRI', label: 'BRI' },
                            { value: 'BNI', label: 'BNI' },
                        ] }
                    />
                </Form.Item>
                <Form.Item
                    style={ { width: '100%' } }
                    name="dateSalary"
                    label="Tanggal Gaji"
                    rules={ [ { required: true, message: 'Tanggal diperlukan!' } ] }
                >
                    <DatePicker
                        placeholder='Pilih Tanggal'
                        style={ { width: '100%' } }
                        format="DD/MM/YYYY"
                    />
                </Form.Item>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Popconfirm
                        title="Apakah kamu yakin mengupdate data ini?"
                        onConfirm={ () => form.submit() }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' >
                            Update
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Apakah kamu yakin menghapus data ini?"
                        onConfirm={ handleDelete }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger type='default' >
                            Hapus
                        </Button>
                    </Popconfirm>
                </div>
            </Form>
        </Spin>
    );
};

export default EditEmployeeSalary;
