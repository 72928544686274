import React, { useState, useEffect } from 'react';
import { Form, Button, message, Spin, Input, Popconfirm } from 'antd';
import axios from 'axios';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const EditClient = ( { onClose, fetchData, recordId } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const [ listClient, setListClient ] = useState( [] );
    const [ currentClient, setCurrentClient ] = useState();
    useEffect( () =>
    {
        const fetchListData = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/loanData` );
                const data = response.data;
                const formData = data.find( item => item._id === recordId );
                setListClient( data );
                setCurrentClient( formData.namaNasabah );
                form.setFieldsValue( formData );
            } catch ( error )
            {
                onClose();
                message.error( 'Gagal mengambil data dari server' );
            } finally
            {
                setLoading( false );
            }
        };

        if ( recordId )
        {
            fetchListData();
        }
    }, [ recordId, form, onClose ] );
    const handleDelete = async () =>
    {
        try
        {
            await axiosConfig.delete( `${ process.env.REACT_APP_LOCAL_API }/loanData/${ recordId }` );
            onClose();
            message.success( 'Berhasil menghapus data nasabah' );
            fetchData();
        } catch ( error )
        {
            message.error( 'Gagal menghapus data nasabah' );
        }
    }
    const onFinish = async ( values ) =>
    {
        if ( ( values.namaNasabah !== currentClient ) &&
            ( listClient.some( name => name.namaNasabah === values.namaNasabah ) ) )
        {
            message.error( `Data nasabah ${ values.namaNasabah } sudah ada` );
            return;
        }

        const dataNasabah = {
            ...values,
            namaNasabah: values.namaNasabah,
        };

        try
        {
            setLoading( true );
            await axiosConfig.put( `${ process.env.REACT_APP_LOCAL_API }/loanData/${ recordId }`, dataNasabah );
            message.success( 'Data nasabah berhasil diperbarui' );
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            message.error( 'Terjadi kesalahan saat memperbarui data' );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <Form.Item
                    style={ { width: '100%' } }
                    label="Nama Nasabah"
                    name="namaNasabah"
                    rules={ [ { required: true, message: 'Nama diperlukan!' } ] }
                    normalize={ ( value ) => value && value.toUpperCase() }
                >
                    <Input placeholder="Masukkan Nama Nasabah" />
                </Form.Item>
                <div style={ { display: 'flex', gap: 5 } }>
                    <Popconfirm
                        title="Apakah kamu yakin melakukan update data ini ?"
                        onConfirm={ () => form.submit() }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary'  >
                            Update
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Apakah kamu yakin menghapus data ini ?"
                        onConfirm={ handleDelete }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='default' danger  >
                            Hapus
                        </Button>
                    </Popconfirm>
                </div>
            </Form>
        </Spin>
    );
};

export default EditClient;
