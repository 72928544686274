import React, { useEffect, useState } from 'react';
import { Button, Drawer, Table, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { CiEdit } from 'react-icons/ci';
import { SlRefresh } from "react-icons/sl";
import 'dayjs/locale/id';
import NewClient from './tambahNasabah';
import EditClient from './editNasabah';
dayjs.locale( 'id' );

const ListLoan = () =>
{
    const [ data, setData ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ drawerEdit, setDrawerEdit ] = useState( false );
    const [ editRecordId, setEditRecordId ] = useState( null );
    const [ drawerTambahNasabah, setDrawerTambahNasabah ] = useState( false );
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/loanData` );
            const data = response.data;
            const tableData = data.map( db => ( {
                _id: db._id,
                namaNasabah: db.namaNasabah,
                jumlahPinjaman: db.jumlahPinjaman || 0,
                sisaPinjaman: db.riwayatPinjaman.reduce( ( total, item ) => total + ( item.loanValue || 0 ), 0 ),
                pinjamanTerbayar: db.riwayatPembayaran.reduce( ( total, item ) => total + ( item.paymentValue || 0 ), 0 ),
            } ) )
            setData( tableData );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        } finally
        {
            setLoading( false );
        }
    };

    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };

    useEffect( () =>
    {
        fetchData();
    }, [] );
    const columns = [
        {
            title: 'Aksi',
            key: 'action',
            width: '10%',
            align: 'center',
            render: ( text, record ) => (
                <Button type='default' onClick={ () => handleEditNasabah( record._id ) } icon={ <CiEdit /> } />
            ),
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Nasabah</p>,
            dataIndex: 'namaNasabah',
            align: 'left',
            width: '20vw',
        },
        {
            title: 'Total Pinjaman',
            dataIndex: 'jumlahPinjaman',
            width: 'auto',
            align: 'center',
            render: value => convertToIDR( value )
        },
        {
            title: 'Rincian Pinjaman',
            width: 'auto',
            align: 'center',
            children: [
                {
                    title: 'Pinjaman Terbayar',
                    dataIndex: 'pinjamanTerbayar',
                    width: 'auto',
                    align: 'center',
                    render: value => value ? convertToIDR( value ) : undefined,
                },
                {
                    title: 'Sisa Pinjaman',
                    dataIndex: 'sisaPinjaman',
                    width: 'auto',
                    align: 'center',
                    render: value => value ? convertToIDR( value ) : undefined,
                },

            ]
        }
    ];
    const handleCloseTambahNasabah = () =>
    {
        setDrawerTambahNasabah( false );
    };
    const handleTambahNasabah = () =>
    {
        setDrawerTambahNasabah( true );
    };
    const handleEditNasabah = recordId =>
    {
        setEditRecordId( recordId );
        setDrawerEdit( true );
    };
    const handleCloseEdit = () =>
    {
        setDrawerEdit( false );
        setEditRecordId( null );
    };
    const handleRefresh = () =>
    {
        fetchData();
    };
    return (
        <div>
            <div style={ { padding: '0 5px' } }>
                <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                    <Button style={ { padding: 5 } } onClick={ handleRefresh } icon={ <SlRefresh /> } />
                    <Button onClick={ handleTambahNasabah }>
                        Tambah Nasabah
                    </Button>
                </div>
                <Table
                    bordered
                    columns={ columns }
                    showSorterTooltip={ false }
                    dataSource={ data }
                    loading={ loading }
                    rowKey="_id"
                    pagination={ { pageSize: 20 } }
                    size="small"
                    scroll={ { y: 400 } }
                />
            </div>
            <div>
                <Drawer
                    title="Edit Nasabah"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEdit }
                    visible={ drawerEdit }
                    width="50%"
                >
                    <EditClient
                        key={ `edit-form-key-${ editRecordId }` }
                        recordId={ editRecordId }
                        onClose={ handleCloseEdit }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Tambah Nasabah"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseTambahNasabah }
                    visible={ drawerTambahNasabah }
                    width="40%"
                >
                    <NewClient onClose={ handleCloseTambahNasabah } fetchData={ fetchData } />
                </Drawer>
            </div>
        </div>
    );
};

export default ListLoan;
