import { DatePicker, InputNumber, Select, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
dayjs.locale( 'id' );

const CashFlow = () =>
{
    const [ loading, setLoading ] = useState( false );
    const [ cashFlowData, setCashFlowData ] = useState( [] );
    const [ action, setAction ] = useState( null );
    const [ selectedDate, setSelectedDate ] = useState( dayjs() );
    const [ totalPengeluaran, setTotalPengeluaran ] = useState( 0 );
    const [ totalPemasukan, setTotalPemasukan ] = useState( 0 );
    const [ filteredData, setFilteredData ] = useState( [] );
    const [ filterJenis, setFilterJenis ] = useState( [] );
    const fetchCashFlow = async () =>
    {
        try
        {
            setLoading( true );
            const responses = await axios.all( [
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/lahan` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/materials` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/sales` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/otherPayment` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/gajiTukang` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/employeeList` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/pengeluaranLain` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/loanData` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/customers` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/pemasukanTambahan` ),
            ] );
            const [ lahan,
                material,
                penjualan,
                media,
                gajiTukang,
                karyawan,
                pengeluaranTT,
                pinjaman,
                customers,
                pemasukanTM,
            ] = responses.map( response => response.data );
            const dataLahan = lahan.flatMap( lh => lh.landPayment.map( py => ( {
                key: `lahan-${ lh._id }-${ py.tanggalByrLhn }`,
                keterangan: `Bayar ${ py.jumlahByrLhn ? 'Lahan' : 'Kompensasi Lahan' } ${ lh.farmerName } [ ${ lh.landCode } ]`,
                jenis: 'Pengeluaran',
                jumlah: py.jumlahByrLhn || py.kompensasiLhn,
                metode: py.metodeByrLhn,
                tanggal: py.tanggalByrLhn,
            } ) ) );
            const dataMaterial = material.map( mt => ( {
                key: `material-${ mt._id }`,
                keterangan: mt.namaMaterial,
                jenis: 'Pengeluaran',
                jumlah: mt.hargaMaterial,
                metode: mt.metodeBayarMaterial,
                tanggal: mt.tanggalBeliMaterial,
            } ) );
            const dataPenjualan = penjualan.flatMap( pj => pj.dataKomisi.map( dk => ( {
                key: `penjualan-${ pj._id }-${ dk.tanggalByrKms }`,
                keterangan: `Fee ${ pj.namaMarketing }`,
                jenis: 'Pengeluaran',
                jumlah: dk.jumlahByrKms,
                metode: dk.metodeByrKms,
                tanggal: dk.tanggalByrKms,
            } ) ) );
            const dataMedia = media.map( md => ( {
                key: `media-${ md._id }`,
                keterangan: md.nama ? `Kasih Media ${ md.nama } [ ${ md.idCard } ]` : `Kasih ${ md.jenis }`,
                jenis: 'Pengeluaran',
                jumlah: md.jumlahKasih,
                metode: 'TUNAI',
                tanggal: md.tanggalKasih,
            } ) );
            const dataGTukang = gajiTukang.map( gt => ( {
                key: `gajiTukang-${ gt._id }`,
                keterangan: 'Gaji Tukang',
                jenis: 'Pengeluaran',
                jumlah: gt.totalPembayaranGaji,
                metode: 'TUNAI',
                tanggal: gt.tanggalGaji,
            } ) );
            const dataKaryawan = karyawan.map( ky => ( {
                key: `karyawan-${ ky._id }`,
                keterangan: `Gaji ${ ky.namaKaryawan }`,
                jenis: 'Pengeluaran',
                jumlah: ky.totalGajiKaryawan,
                metode: ky.metodeGajiKaryawan,
                tanggal: ky.tanggalGajiKaryawan,
            } ) );
            const dataPengeluaranTT = pengeluaranTT.map( pt => ( {
                key: `pengeluaranTT-${ pt._id }`,
                keterangan: `[ Beli / Bayar ] ${ pt.catatanPTT }`,
                jenis: 'Pengeluaran',
                jumlah: pt.hargaPTT,
                metode: pt.metodePTT,
                tanggal: pt.tanggalPTT,
            } ) );
            const dataPinjaman = pinjaman.flatMap( bon => [
                ...bon.riwayatPinjaman.map( pi => ( {
                    key: `pinjaman-${ pi._id }`,
                    keterangan: `Bon ${ bon.namaNasabah }`,
                    jenis: 'Pengeluaran',
                    jumlah: pi.loanValue,
                    metode: pi.loanMethod,
                    tanggal: pi.loanDate,
                } ) ),
                ...bon.riwayatPembayaran.map( pe => ( {
                    key: `pembayaran-${ pe._id }`,
                    keterangan: `Bayar Bon ${ bon.namaNasabah }`,
                    jenis: 'Pemasukan',
                    jumlah: pe.paymentValue,
                    metode: pe.paymentMethod,
                    tanggal: pe.paymentDate,
                } ) ),
            ] );
            const dataCustomer = customers.flatMap( cs => [
                {
                    key: `customer-${ cs._id }`,
                    keterangan: `Booking Kavling No ${ cs.blokKavling === 'TANPABLOK' ? cs.noKavling : cs.blokKavling + cs.noKavling } ${ cs.lokasiKavling.charAt( 0 ).toUpperCase() + cs.lokasiKavling.slice( 1 ).toLowerCase().replace( /[^a-zA-Z\s]/g, '' ) }`,
                    tanggal: cs.tanggalBooking,
                    jumlah: cs.jumlahUTJ,
                    metode: cs.methodByrUTJ,
                    jenis: 'Pemasukan',
                },
                ...cs.pembayaranAngsuran.map( angs => ( {
                    key: `customer-${ cs._id }-angsuran-${ angs.tanggalByrAngs }`,
                    keterangan: `Angs.  ke ${ angs.angsKe } ${ cs.namaCustomer
                        .toLowerCase()
                        .split( ' ' )
                        .map( word => word.charAt( 0 ).toUpperCase() + word.slice( 1 ) )
                        .join( ' ' )
                        .slice( 0, 20 ) } No ${ cs.blokKavling === 'TANPABLOK' ? cs.noKavling : cs.blokKavling + cs.noKavling } ${ cs.lokasiKavling.charAt( 0 ).toUpperCase() + cs.lokasiKavling.slice( 1 ).toLowerCase().replace( /[^a-zA-Z\s]/g, '' ) }`,
                    tanggal: angs.tanggalByrAngs,
                    jumlah: angs.jumlahByrAngs,
                    metode: angs.methodByrAngs,
                    jenis: 'Pemasukan',
                } ) ),
                ...cs.dataBayarDenda.map( denda => ( {
                    key: `denda = ${ cs._id }`,
                    keterangan: `Pembayaran Denda ${ cs.namaCustomer
                        .toLowerCase()
                        .split( ' ' )
                        .map( word => word.charAt( 0 ).toUpperCase() + word.slice( 1 ) )
                        .join( ' ' )
                        .slice( 0, 20 ) } No ${ cs.blokKavling === 'TANPABLOK' ? cs.noKavling : cs.blokKavling + cs.noKavling } ${ cs.lokasiKavling.charAt( 0 ).toUpperCase() + cs.lokasiKavling.slice( 1 ).toLowerCase().replace( /[^a-zA-Z\s]/g, '' ) }`,
                    tanggal: denda.tanggalByrDenda,
                    jumlah: denda.jumlahByrDenda,
                    metode: denda.metodeByrDenda,
                    jenis: 'Pemasukan',
                } ) ),
                ...cs.pembayaranDP.map( DP => ( {
                    key: `customer-${ cs._id }-dp-${ DP.tanggalByrDp }`,
                    keterangan: `Pembayaran  DP ${ cs.namaCustomer
                        .toLowerCase()
                        .split( ' ' )
                        .map( word => word.charAt( 0 ).toUpperCase() + word.slice( 1 ) )
                        .join( ' ' )
                        .slice( 0, 20 ) } No ${ cs.blokKavling === 'TANPABLOK' ? cs.noKavling : cs.blokKavling + cs.noKavling } ${ cs.lokasiKavling.charAt( 0 ).toUpperCase() + cs.lokasiKavling.slice( 1 ).toLowerCase().replace( /[^a-zA-Z\s]/g, '' )
                        } ke ${ DP.dpKe }`,
                    tanggal: DP.tanggalByrDp,
                    jumlah: DP.jumlahByrDp,
                    metode: DP.metodeBayarDp,
                    jenis: 'Pemasukan',
                } ) ),
                ...cs.pembayaranAirLampu.map( air => ( {
                    key: `customer-${ cs._id }-air-${ air.tanggalByrAir }`,
                    keterangan: `Pembayaran Air ${ cs.namaCustomer
                        .toLowerCase()
                        .split( ' ' )
                        .map( word => word.charAt( 0 ).toUpperCase() + word.slice( 1 ) )
                        .join( ' ' )
                        .slice( 0, 20 ) } No ${ cs.blokKavling === 'TANPABLOK' ? cs.noKavling : cs.blokKavling + cs.noKavling } ${ cs.lokasiKavling.charAt( 0 ).toUpperCase() + cs.lokasiKavling.slice( 1 ).toLowerCase().replace( /[^a-zA-Z\s]/g, '' ) }`,
                    tanggal: air.tanggalByrAir,
                    jumlah: air.jumlahByrAir,
                    metode: air.metodeByrAir,
                    jenis: 'Pemasukan',
                } ) ),
                ...cs.pembayaranAirLampu.map( lmp => ( {
                    key: `customer-${ cs._id }-lampu-${ lmp.tanggalByrLm }`,
                    keterangan: `Pembayaran Lampu ${ cs.namaCustomer
                        .toLowerCase()
                        .split( ' ' )
                        .map( word => word.charAt( 0 ).toUpperCase() + word.slice( 1 ) )
                        .join( ' ' )
                        .slice( 0, 20 ) } No ${ cs.blokKavling === 'TANPABLOK' ? cs.noKavling : cs.blokKavling + cs.noKavling } ${ cs.lokasiKavling.charAt( 0 ).toUpperCase() + cs.lokasiKavling.slice( 1 ).toLowerCase().replace( /[^a-zA-Z\s]/g, '' ) }`,
                    tanggal: lmp.tanggalByrLm,
                    jumlah: lmp.jumlahByrLm,
                    metode: lmp.metodeByrLm,
                    jenis: 'Pemasukan',
                } ) ),
            ] );
            const dataPMTambahan = pemasukanTM.map( pt => ( {
                keterangan: pt.ofWhom,
                jenis: 'Pemasukan',
                jumlah: pt.howMuch,
                tanggal: pt.dateGet,
                metode: pt.incomeMethod,
            } ) );
            const fullData = [
                ...dataLahan,
                ...dataMaterial,
                ...dataPenjualan,
                ...dataMedia,
                ...dataGTukang,
                ...dataKaryawan,
                ...dataPengeluaranTT,
                ...dataPinjaman,
                ...dataCustomer,
                ...dataPMTambahan,
            ];
            setCashFlowData( fullData );
            setFilterJenis( getUniqueJenis( fullData ) );
        } catch ( error )
        {
            console.error( 'Error fetching cash flow data:', error );
        } finally
        {
            setLoading( false );
        }
    };
    const getUniqueJenis = ( cashFlowData ) =>
    {
        const uniqueTypes = [ ...new Set( cashFlowData.map( item => item.jenis ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    useEffect( () =>
    {
        fetchCashFlow();
    }, [] );
    const handleChangeDate = ( date ) =>
    {
        if ( date )
        {
            setSelectedDate( date );
        } else
        {
            setSelectedDate( dayjs() );
        }
    };
    const handleSelectedAct = ( act ) =>
    {
        if ( act )
        {
            setAction( act );
        } else
        {
            setAction( null )
        }
    };
    useEffect( () =>
    {
        const dateSelected = dayjs( selectedDate, 'DD/MM/YYYY' )
        const filterData = cashFlowData.filter( f =>
            dayjs( f.tanggal, 'DD/MM/YYYY' ).isSame( dateSelected, ( action ? action : 'day' ) ) )

        const pengeluaran = filterData
            .filter( f => f.jenis === 'Pengeluaran' )
            .reduce( ( total, item ) => total + ( item.jumlah || 0 ), 0 );
        const pemasukan = filterData
            .filter( f => f.jenis === 'Pemasukan' )
            .reduce( ( total, item ) => total + ( item.jumlah || 0 ), 0 );
        const sortData = filterData
            .map( fd => ( {
                keterangan: fd.keterangan,
                jenis: fd.jenis,
                jumlah: fd.jumlah,
                tanggal: dayjs( fd.tanggal, 'DD/MM/YYYY' ).format( 'DD/MM/YYYY' ),
                metode: fd.metode,
            } ) )
        setTotalPengeluaran( pengeluaran );
        setTotalPemasukan( pemasukan );
        setFilteredData( sortData );
    }, [ action, cashFlowData, selectedDate ] );
    const cashflowColumns = [
        {
            title: <p style={ { textAlign: 'center' } }>Keterangan</p>,
            dataIndex: 'keterangan',
            key: 'keterangan',
            align: 'left',
            render: value => value.toUpperCase(),
        },
        {
            title: 'Jenis',
            dataIndex: 'jenis',
            key: 'jenis',
            align: 'center',
            width: '10%',
            filters: filterJenis,
            onFilter: ( value, record ) => record.jenis.includes( value ),
        },
        {
            title: 'Jumlah',
            dataIndex: 'jumlah',
            key: 'jumlah',
            align: 'center',
            width: '15%',
            render: value => convertToIDR( value ),
        },
        {
            title: 'Tanggal',
            dataIndex: 'tanggal',
            key: 'tanggal',
            align: 'center',
            width: '15%',
            sorter: ( a, b ) => dayjs( a.tanggal, 'DD/MM/YYYY' ).diff( dayjs( b.tanggal, 'DD/MM/YYYY' ) ),
            sortDirections: [ 'descend', 'ascend' ],
            defaultSortOrder: 'ascend',

        },
        {
            title: 'Metode',
            dataIndex: 'metode',
            key: 'metode',
            align: 'center',
            width: '15%',
        },
    ];
    return (
        <div style={ { margin: '0 5px' } }>
            <div style={ { margin: '10px 0', display: 'flex', gap: 5, alignItems: 'center' } }>
                <Select
                    style={ { width: '20%' } }
                    placeholder='Pilih Tipe'
                    onChange={ handleSelectedAct }
                    options={ [
                        { value: 'day', label: 'Harian' },
                        { value: 'week', label: 'Mingguan' },
                        { value: 'month', label: 'Bulanan' },
                        { value: 'year', label: 'Tahunan' },
                    ] } />
                { action === 'day' && (
                    <DatePicker
                        style={ { width: '50%' } }
                        placeholder='Pilih Hari'
                        picker='day'
                        onChange={ handleChangeDate }
                        allowClear={ false }
                        format={ ( value ) => dayjs( value ).format( 'dddd' ) }
                    />
                ) }
                { action === 'week' && (
                    <DatePicker.WeekPicker
                        style={ { width: '50%' } }
                        placeholder='Pilih Minggu'
                        picker='week'
                        onChange={ handleChangeDate }
                        allowClear={ false }
                        format={ ( value ) => dayjs( value ).format( 'dddd' ) }
                    />
                ) }
                { action === 'month' && (
                    <DatePicker.MonthPicker
                        style={ { width: '50%' } }
                        placeholder='Pilih Bulan'
                        picker='month'
                        onChange={ handleChangeDate }
                        allowClear={ false }
                        format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                    />
                ) }
                { action === 'year' && (
                    <DatePicker.YearPicker
                        style={ { width: '50%' } }
                        placeholder='Pilih Tahun'
                        picker='year'
                        onChange={ handleChangeDate }
                        allowClear={ false }
                        format={ ( value ) => dayjs( value ).format( 'YYYY' ) }
                    />
                ) }
                <InputNumber
                    readOnly
                    style={ { width: '100%' } }
                    addonBefore={ totalPengeluaran > 0 && 'Pengeluaran' }
                    value={ totalPengeluaran > 0 && convertToIDR( totalPengeluaran ) }
                    placeholder={ totalPengeluaran < 1 && 'Tidak ada uang keluar' } />
                <InputNumber
                    readOnly
                    style={ { width: '100%' } }
                    addonBefore={ totalPemasukan > 0 && 'Pemasukan' }
                    value={ totalPemasukan > 0 && convertToIDR( totalPemasukan ) }
                    placeholder={ totalPemasukan < 1 && 'Tidak ada uang masuk' } />
            </div>
            <Table
                dataSource={ filteredData }
                columns={ cashflowColumns }
                size='small'
                loading={ loading }
                showSorterTooltip={ false }
                scroll={ { y: 400 } }
            />
        </div>
    );
};

export default CashFlow;
