import { Button, Form, Input, InputNumber, message, Spin } from 'antd'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const NewEmployee = ( { onClose, fetchData } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const [ listEmployee, setListEmployee ] = useState( [] );
    useEffect( () =>
    {
        const fetchListEmployee = async () =>
        {
            setLoading( true );
            try
            {
                const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/employeeList` );
                setListEmployee( response.data );
            } catch ( error )
            {
                message.error( 'Gagal mendapatkan list karyawan' );
                onclose();
            } finally
            {
                setLoading( false );
            }
        };
        fetchListEmployee();
    }, [] )
    const onFinish = async ( values ) =>
    {
        const existingEmployee = values.employeeName === listEmployee.employeeName;
        if ( existingEmployee )
        {
            message.error( `Data ${ values.employeeName } sudah ada` );
            return;
        }

        const newEmployee = {
            ...values,
            employeeName: values.employeeName,
            employeeAddress: values.employeeAddress,
            employeePhoneNumber: values.employeePhoneNumber,
            NIKemployee: values.NIKemployee,
            basicSalary: values.basicSalary,
        }
        try
        {
            setLoading( true );
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/employeeList`, newEmployee );
            message.success( 'Data karyawan baru berhasil ditambahkan' );
            form.resetFields();
            fetchData()
            onClose();
        } catch ( error )
        {
            message.error( 'Gagal menambahkan karyawan baru' );
        } finally
        {
            setLoading( false );
        }
    }
    return (
        <Spin spinning={ loading }>
            <Form onFinish={ onFinish } form={ form } layout='vertical'>
                <Form.Item
                    label='Nama Karyawan'
                    name='employeeName'
                    normalize={ ( value ) => value.toUpperCase() }
                    rules={ [ { required: true, message: 'Nama diperlukan!' } ] }
                >
                    <Input placeholder='Masukkan Nama Karyawan' />
                </Form.Item>
                <Form.Item
                    label='Nomor Induk Kependudukan'
                    name='NIKemployee'
                    rules={ [ { required: true, message: 'Alamat diperlukan!' } ] }
                >
                    <InputNumber placeholder='Masukkan NIK KTP' style={ { width: '100%' } } />
                </Form.Item>
                <Form.Item
                    label='Alamat Karyawan'
                    name='employeeAddress'
                    normalize={ ( value ) => value.toUpperCase() }
                    rules={ [ { required: true, message: 'Alamat diperlukan!' } ] }
                >
                    <Input placeholder='Masukkan Alamat Karyawan' />
                </Form.Item>
                <Form.Item
                    label='Nomor HP Karyawan'
                    name='employeePhoneNumber'
                    rules={ [ { required: true, message: 'Nomor HP diperlukan!' } ] }
                >
                    <InputNumber addonBefore='+62' style={ { width: '100%' } } placeholder='Masukkan Nomor HP' />
                </Form.Item>
                <Form.Item
                    label='Gaji Pokok'
                    name='basicSalary'
                    rules={ [ { required: true, message: 'Gaji Pokok diperlukan!' } ] }
                >
                    <InputNumber
                        addonBefore='Rp'
                        style={ { width: '100%' } }
                        placeholder='Masukkan Gaji Pokok' />
                </Form.Item>
                <Button htmlType='submit' type='primary'>Tambah Karyawan</Button>
            </Form>
        </Spin>
    )
}

export default NewEmployee
