import axios from 'axios';
import { message } from 'antd';

const getAuthToken = () =>
{
    const userData = localStorage.getItem( 'user_data' );
    return userData ? JSON.parse( userData ).userToken : null;
};

const CreateAxiosInstance = () =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;

    const instance = axios.create( {
        baseURL: apiUrl
    } );
    instance.interceptors.request.use( config =>
    {
        const authToken = getAuthToken();
        if ( authToken )
        {
            config.headers.Authorization = `Bearer ${ authToken }`;
        }
        return config;
    } );
    instance.interceptors.response.use(
        response => response,
        error =>
        {
            if ( error.response )
            {
                if ( error.response.status === 401 )
                {
                    message.info( 'Session expired! Redirecting to login...' );
                    localStorage.removeItem( 'user_data' );
                    window.location.href = '/login';
                }
                console.error( 'API Error:', error.response.data );
            } else
            {
                console.error( 'Network Error:', error.message );
            }
            return Promise.reject( error );
        }
    );

    return instance;
};

export default CreateAxiosInstance;
