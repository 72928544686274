import { Descriptions, message, Typography, List, DatePicker } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/id';
dayjs.locale( 'id' );

const { YearPicker } = DatePicker;

const CekDataCustomer = ( { recordId, onClose } ) =>
{
    const [ database, setDatabase ] = useState( undefined );
    const [ historyPayment, setHistoryPayment ] = useState( [] );
    const [ selectedYear, setSelectedYear ] = useState( dayjs() );

    useEffect( () =>
    {
        const fetchDataById = async () =>
        {
            try
            {
                const response1 = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/customer/${ recordId }` );
                const data = response1.data;
                const subData1 = data.pembayaranAngsuran || [];
                const subData2 = data.pembayaranDP || [];
                const subData3 = data.pembayaranTempo || [];
                const riwayatPembayaran = [
                    ...subData1,
                    ...subData2,
                    ...subData3,
                ];
                const totalAngsuran = ( subData1.reduce( ( total, item ) => total + ( item.jumlahByrAngs || 0 ), 0 ) );
                const totalDP = ( subData2.reduce( ( total, item ) => total + ( item.jumlahByrDp || 0 ), 0 ) );
                const totalTempo = ( subData3.reduce( ( total, item ) => total + ( item.jumlahByrTempo || 0 ), 0 ) );
                const totalUangMasuk = totalAngsuran + totalDP + totalTempo + ( data.jumlahUTJ || 0 );
                const piutang = ( data.jenisPembelian === 'CASH' || data.jenisPembelian === 'CASH TEMPO' )
                    ? ( data.hargaKavling - totalUangMasuk )
                    : ( ( ( data.angsuranKavling * data.tenorKavling ) + data.uangMukaKavling ) - totalUangMasuk ) - data.potonganHarga;
                const bungaKavling = data.angsuranKavling > 0
                    ? ( ( ( data.angsuranKavling * data.tenorKavling ) + data.uangMukaKavling ) - data.potonganHarga ) - data.hargaKavling
                    : 0;
                const dendaTerbayar = subData1.reduce( ( total, item ) => total + ( item.dendaAngs || 0 ), 0 )
                const sisaDenda = data.dendaCustomer > 0 && data.dendaCustomer - ( dendaTerbayar || 0 )
                const updateData = {
                    ...data,
                    totalAngsuran,
                    totalDP,
                    totalUangMasuk,
                    piutang,
                    bungaKavling,
                    dendaTerbayar,
                    sisaDenda
                };
                setHistoryPayment( riwayatPembayaran );
                setDatabase( updateData );
            } catch ( error )
            {
                message.error( "Gagal mengambil data dari server" );
            }
        };

        if ( recordId )
        {
            fetchDataById();
        }
    }, [ recordId, onClose ] );

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    const handleYearChange = ( date ) =>
    {
        setSelectedYear( date );
    };
    const filteredData = historyPayment.filter( ( item ) =>
    {
        if ( selectedYear )
        {
            return dayjs( ( item.tanggalByrAngs || item.tanggalByrDp || item.tanggalByrTempo ), 'DD/MM/YYYY' ).isSame( selectedYear, 'year' );
        } else
        {
            return true;
        }
    } );
    return (
        <div>
            { database && (
                <Descriptions title={ `${ database.namaCustomer }` } column={ 2 }>
                    <Descriptions.Item label="Alamat Pelanggan">{ database.alamatCustomer }</Descriptions.Item>
                    <Descriptions.Item label="Nomor Pelanggan">{ `0${ database.telpCustomer }` }</Descriptions.Item>
                    <Descriptions.Item label="Harga Properti">{ convertToIDR( database.hargaKavling || 0 ) }</Descriptions.Item>
                    { database.potonganHarga ? (
                        <Descriptions.Item label={ database.potonganHarga < 0
                            ? "Bon Kantor"
                            : "Potongan Harga"
                        }>{ convertToIDR( database.potonganHarga || 0 ) }</Descriptions.Item>
                    ) : undefined }
                    <Descriptions.Item label="Rencana DP">{ convertToIDR( database.uangMukaKavling || 0 ) }</Descriptions.Item>
                    <Descriptions.Item label="Angsuran Properti">{ convertToIDR( database.angsuranKavling || 0 ) }</Descriptions.Item>
                    <Descriptions.Item label="Tenor Properti">{ `${ database.tenorKavling } BULAN` }</Descriptions.Item>
                    <Descriptions.Item label="Blok Properti">{ database.blokKavling === 'TANPABLOK' ?
                        database.noKavling : database.blokKavling + database.noKavling }</Descriptions.Item>
                    <Descriptions.Item label="Jumlah UTJ">{ convertToIDR( database.jumlahUTJ || 0 ) }</Descriptions.Item>
                    <Descriptions.Item label="Tanggal Booking">{ database.tanggalBooking }</Descriptions.Item>
                    <Descriptions.Item label="Tanggal IJB">{ database.tanggalIJB }</Descriptions.Item>
                    <Descriptions.Item label="Ukuran Kavling">{ database.ukuranKavling }</Descriptions.Item>
                    { database.luasBangunanKavling &&
                        <Descriptions.Item label="Luas Bangunan">{ database.luasBangunanKavling }</Descriptions.Item>
                    }
                    <Descriptions.Item label="Jenis Properti">{ database.jenisProperty }</Descriptions.Item>
                    <Descriptions.Item label="Jenis Pembelian">{ database.jenisPembelian }</Descriptions.Item>
                    <Descriptions.Item label="Lokasi Properti">{ database.lokasiKavling }</Descriptions.Item>
                    <Descriptions.Item label="Total Uang Masuk">{ convertToIDR( database.totalUangMasuk ) }</Descriptions.Item>
                    <Descriptions.Item label="Total Piutang">{ convertToIDR( database.piutang ) }</Descriptions.Item>
                    <Descriptions.Item label="Angsuran Masuk">{ convertToIDR( database.totalAngsuran ) }</Descriptions.Item>
                    <Descriptions.Item label="DP Masuk">{ convertToIDR( database.totalDP ) }</Descriptions.Item>
                    <Descriptions.Item label="Bunga">{ convertToIDR( database.bungaKavling ) }</Descriptions.Item>
                    { database.dendaCustomer > 0 && (
                        <Descriptions.Item label="Denda">{ convertToIDR( database.sisaDenda ) }</Descriptions.Item>
                    ) }
                    { database.dendaTerbayar > 0 && (
                        <Descriptions.Item label="Denda Terbayar">{ convertToIDR( database.dendaTerbayar ) }</Descriptions.Item>
                    ) }
                </Descriptions>
            ) }
            <YearPicker
                style={ { width: '100%', marginBottom: 5 } }
                onChange={ handleYearChange }
                value={ selectedYear }
                picker="year"
                format='YYYY'
                placeholder='Pilih Tahun'
            />
            <List
                bordered
                dataSource={ filteredData }
                header='Riwayat Pembayaran'
                renderItem={ ( item ) => (
                    <List.Item>
                        <div>
                            { item.jumlahByrAngs && (
                                <>
                                    <Typography.Text strong>Jumlah Bayar Angsuran: </Typography.Text>{ convertToIDR( item.jumlahByrAngs ) }<br />
                                </>
                            ) }
                            { item.tanggalByrAngs && (
                                <>
                                    <Typography.Text strong>Tanggal Bayar Angsuran: </Typography.Text>{ dayjs( item.tanggalByrAngs, 'DD/MM/YYYY' ).format( 'DD MMMM YYYY' ) }<br />
                                </>
                            ) }
                            { item.tanggalByrAngs2 && (
                                <>
                                    <Typography.Text strong>Untuk Angsuran Bulan: </Typography.Text>{ dayjs( item.tanggalByrAngs2, 'DD/MM/YYYY' ).format( 'MMMM YYYY' ) }<br />
                                </>
                            ) }
                            { item.metodeByrAngs && (
                                <>
                                    <Typography.Text strong>Metode Bayar Angsuran: </Typography.Text>{ item.metodeByrAngs }<br />
                                </>
                            ) }
                            { item.angsKe && (
                                <>
                                    <Typography.Text strong>Bayar Angsuran Ke: </Typography.Text>{ item.angsKe }<br />
                                </>
                            ) }
                            { item.dendaAngs && (
                                <>
                                    <Typography.Text>Bayar Denda Angsuran: </Typography.Text>{ convertToIDR( item.dendaAngs ) }<br />
                                </>
                            ) }
                            { item.jumlahByrDp && (
                                <>
                                    <Typography.Text strong>Jumlah Bayar DP: </Typography.Text>{ convertToIDR( item.jumlahByrDp ) }<br />
                                </>
                            ) }
                            { item.tanggalByrDp && (
                                <>
                                    <Typography.Text strong>Tanggal Bayar DP: </Typography.Text>{ dayjs( item.tanggalByrDp, 'DD/MM/YYYY' ).format( 'DD MMMM YYYY' ) }<br />
                                </>
                            ) }
                            { item.metodeByrDp && (
                                <>
                                    <Typography.Text strong>Metode Bayar DP: </Typography.Text>{ item.metodeByrDp }<br />
                                </>
                            ) }
                            { item.dpKe && (
                                <>
                                    <Typography.Text strong>Bayar DP Ke: </Typography.Text>{ item.dpKe }<br />
                                </>
                            ) }

                            { item.jumlahByrTempo && (
                                <>
                                    <Typography.Text strong>Jumlah Bayar DP: </Typography.Text>{ convertToIDR( item.jumlahByrTempo ) }<br />
                                </>
                            ) }
                            { item.tanggalByrTempo && (
                                <>
                                    <Typography.Text strong>Tanggal Bayar DP: </Typography.Text>{ dayjs( item.tanggalByrTempo, 'DD/MM/YYYY' ).format( 'DD MMMM YYYY' ) }<br />
                                </>
                            ) }
                            { item.metodeByrTempo && (
                                <>
                                    <Typography.Text strong>Metode Bayar DP: </Typography.Text>{ item.metodeByrTempo }<br />
                                </>
                            ) }
                            { item.bayarTempoKe && (
                                <>
                                    <Typography.Text strong>Bayar DP Ke: </Typography.Text>{ item.bayarTempoKe }<br />
                                </>
                            ) }
                        </div>
                    </List.Item>
                ) }
            />
        </div>
    );
};

export default CekDataCustomer;
