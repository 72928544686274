import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, DatePicker, message, InputNumber, Spin } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';

const { Option } = Select;

const BayarWartawan = ( { fetchData, onClose } ) =>
{
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ type, setType ] = useState();
    const [ loading, setLoading ] = useState( true );
    const [ listMedia, setListMedia ] = useState( [] );
    const [ cardIdList, setCardIdList ] = useState( [] );
    const fetchList = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/mediaList` );
            setListMedia( response.data );
        } catch ( error )
        {
            onClose();
            message.error( 'Gagal mengambil data dari server' );
        } finally
        {
            setLoading( false );
        }
    }
    useEffect( () =>
    {
        fetchList();
    }, [] );
    useEffect( () =>
    {
        setCardIdList( [] );
    }, [ type ] );
    const handleTypePayment = ( value ) =>
    {
        setType( value );
        form.resetFields( [
            'idCard',
            'nama',
            'jumlahKasih',
            'tanggalKasih',
            'keperluan',
        ] );
    }
    const handleCardIdChange = ( value ) =>
    {
        const cardDetail = listMedia.filter( media => media.cardId === value );
        setCardIdList( cardDetail );
        form.resetFields( [
            'nama',
            'wilayah',
            'jumlahKasih',
            'tanggalKasih',
            'keperluan',
        ] );
    }
    const onFinish = async ( values ) =>
    {
        const listPayments = {
            ...values,
            tanggalKasih: dayjs( values.tanggalKasih ).format( 'DD/MM/YYYY' ),
            jenis: values.jenis,
            wilayah: values.wilayah,
            jumlahKasih: values.jumlahKasih,
            nama: values.jenis !== 'MEDIA' ? values.jenis : values.nama,
            keperluan: values.keperluan,
        }
        try
        {
            setLoading( true );
            await axiosConfig.post( `${ process.env.REACT_APP_LOCAL_API }/otherPayment`, listPayments );
            message.success( 'Data Berhasil Ditambahkan' );
            form.resetFields();
            fetchData();
            onClose();
        } catch ( error )
        {
            message.error( 'Gagal menambahkan data' );
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form layout='vertical' onFinish={ onFinish } form={ form }>
                <Form.Item
                    name='jenis'
                    label='Pilih Jenis'
                    rules={ [ { required: true, message: 'Opsi diperlukan!' } ] }
                >
                    <Select
                        placeholder='Pilih Jenis'
                        style={ { width: '100%' } }
                        allowClear
                        onChange={ handleTypePayment }
                        options={ [
                            { value: 'MEDIA', label: 'MEDIA' },
                            { value: 'POLISI', label: 'POLISI' },
                            { value: 'TNI', label: 'TNI' },
                            { value: 'POLRES', label: 'POLRES' },
                            { value: 'POLDA', label: 'POLDA' },
                        ] }
                    />
                </Form.Item>
                { ( type === 'TNI' || type === 'POLISI' || type === 'POLRES' || type === 'POLDA' ) && (
                    <Form.Item
                        label='Wilayah'
                        name='wilayah'
                        rules={ [ { required: true, message: 'Wilayah diperlukan!' } ] }
                    >
                        <Input placeholder='Masukkan Wilayah' />
                    </Form.Item>
                ) }
                { type === 'MEDIA' && (
                    <>
                        <Form.Item
                            name='idCard'
                            label='Id Card'
                            rules={ [ { required: true, message: 'Id Card diperlukan!' } ] }
                        >
                            <Select
                                placeholder='Pilih Id Card'
                                style={ { width: '100%' } }
                                onChange={ handleCardIdChange }
                                showSearch
                            >
                                { Array.from( new Set( listMedia.map( item => item.cardId ) ) )
                                    .map( ( idCard ) =>
                                    {
                                        const media = listMedia.find( item => item.cardId === idCard );
                                        const actPerd = media ? media.activePeriod : null;
                                        return (
                                            <Option key={ media._id } value={ idCard }>
                                                { actPerd ? `${ idCard } - ${ actPerd }` : idCard }
                                            </Option>
                                        );
                                    } ) }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='nama'
                            label='Pilih Wartawan'
                            rules={ [ { required: true, message: 'Nama wartawan diperlukan!' } ] }
                        >
                            <Select placeholder='Pilih Wartawan' style={ { width: '100%' } }>
                                { cardIdList.map( ( list ) => (
                                    <Option key={ list._id } value={ list.name }>
                                        { list.name }
                                    </Option>
                                ) ) }
                            </Select>
                        </Form.Item>
                    </>
                ) }
                <Form.Item
                    name='jumlahKasih'
                    label='Jumlah Bayar'
                    rules={ [ { required: true, message: 'Jumlah diperlukan!' } ] }
                >
                    <InputNumber addonBefore='Rp' placeholder='Masukkan Jumlah' style={ { width: '100%' } } />
                </Form.Item>
                <Form.Item
                    name='tanggalKasih'
                    label='Tanggal Kasih'
                    rules={ [ { required: true, message: 'Tanggal diperlukan!' } ] }
                >
                    <DatePicker format='DD/MM/YYYY' placeholder='Pilih Tanggal' style={ { width: '100%' } } />
                </Form.Item>
                <Form.Item
                    name='keperluan'
                    label='Keperluan'
                    normalize={ ( value ) => value && value.toUpperCase() }
                >
                    <Input placeholder='Masukkan Keperluan' style={ { width: '100%' } } />
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Tambah
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default BayarWartawan;
