import React, { useEffect } from 'react';
import { message } from 'antd';
import dayjs from 'dayjs';

const AlarmReminder = () =>
{

    useEffect( () =>
    {
        const intervalId = setInterval( () =>
        {
            const now = dayjs();

            if ( now.minute() === 0 && now.second() === 0 )
            {
                message.info( `Pengingat: Jangan Lupa Istirahat ${ now.format( 'HH:mm' ) }` );
            }
        }, 1000 );

        return () => clearInterval( intervalId );
    }, [] );

    return null;
};

export default AlarmReminder;
