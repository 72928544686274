// routes/NotFound.js
import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

const NotFound = () => (
    <Result
        status="404"
        title="404"
        subTitle="Maaf, halaman yang Anda cari tidak ditemukan."
        extra={
            <Button type="primary">
                <Link to="/">Kembali ke Beranda</Link>
            </Button>
        }
    />
);

export { NotFound };
