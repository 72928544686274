import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Drawer, InputNumber, Table, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { CiEdit } from 'react-icons/ci';
import { SlRefresh } from "react-icons/sl";
import 'dayjs/locale/id';
import EditLoanPayment from './EditPaymentLoan';
import NewLoanPayment from './newPaymentLoan';
dayjs.locale( 'id' );

const LoanPaymentData = () =>
{
    const [ data, setData ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ drawerEdit, setDrawerEdit ] = useState( false );
    const [ editRecordId, setEditRecordId ] = useState( null );
    const [ drawerTambah, setDrawerTambah ] = useState( false );
    const [ idPayment, setIdPayment ] = useState( null );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ totalPembayaran, setTotalPembayaran ] = useState();

    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/loanData/` );
            const data = response.data;
            const tableData = data.flatMap( db =>
            {
                return db.riwayatPembayaran.map( payment => ( {
                    nama: db.namaNasabah,
                    jumlah: payment.paymentValue,
                    tanggal: payment.paymentDate,
                    metode: payment.paymentMethod,
                    idPayment: payment._id,
                    idClient: db._id,
                } ) );
            } );
            setData( tableData );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        } finally
        {
            setLoading( false );
        }
    };

    const convertToIDR = value =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };

    useEffect( () =>
    {
        fetchData();
    }, [] );
    const handleMonthChange = ( date ) =>
    {
        setSelectedMonth( date );
    };

    const filteredData = data.filter( ( item ) =>
    {
        if ( selectedMonth )
        {
            return dayjs( item.tanggal, 'DD/MM/YYYY' ).isSame( selectedMonth, 'month' );
        } else
        {
            return true;
        }
    } );
    useEffect( () =>
    {
        if ( selectedMonth )
        {
            const totalPembayaran1Bln = filteredData.reduce( ( total, item ) =>
            {
                return total + item.jumlah;
            }, 0 );
            setTotalPembayaran( totalPembayaran1Bln );
        } else
        {
            const totalSemuaPembayaran = data.reduce( ( total, item ) =>
            {
                return total + item.jumlah;
            }, 0 );
            setTotalPembayaran( totalSemuaPembayaran );
        }
    }, [ filteredData, selectedMonth, data ] );
    const columns = [
        {
            title: 'Aksi',
            key: 'action',
            width: '10%',
            align: 'center',
            render: ( text, record ) => (
                <Button type='default' onClick={ () => handleOpenEdit( record ) } icon={ <CiEdit /> } />
            ),
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Nasabah</p>,
            dataIndex: 'nama',
            align: 'left',
            width: '20vw',
        },
        {
            title: 'Pinjaman',
            dataIndex: 'jumlah',
            width: 'auto',
            align: 'center',
            render: value => convertToIDR( value )
        },
        {
            title: 'Tanggal',
            dataIndex: 'tanggal',
            width: 'auto',
            align: 'center',
        },
        {
            title: 'Metode',
            dataIndex: 'metode',
            width: 'auto',
            align: 'center',
        },
    ];
    const handleCloseAdd = () =>
    {
        setDrawerTambah( false );
    };
    const handleOpenAdd = () =>
    {
        setDrawerTambah( true );
    };
    const handleOpenEdit = ( record ) =>
    {
        setIdPayment( record.idPayment )
        setEditRecordId( record.idClient );
        setDrawerEdit( true );
    };
    const handleCloseEdit = () =>
    {
        setDrawerEdit( false );
        setEditRecordId( null );
        setIdPayment( null )
    };
    const handleRefresh = () =>
    {
        fetchData();
    };
    return (
        <div>
            <div style={ { padding: '0 5px' } }>
                <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                    <Button style={ { padding: 5 } } onClick={ handleRefresh } icon={ <SlRefresh /> } />
                    <Button onClick={ handleOpenAdd }>
                        Bayar Pinjaman
                    </Button>
                    <InputNumber
                        addonBefore={ totalPembayaran >= 1 && `Total Pembayaran ${ dayjs( selectedMonth ).format( 'MMMM YYYY' ) }` }
                        readOnly
                        style={ { width: '100%' } }
                        placeholder={ totalPembayaran < 1 && 'Tidak ada pembayaran bon' }
                        value={ totalPembayaran >= 1 && convertToIDR( totalPembayaran ) } />
                    <DatePicker.MonthPicker
                        style={ { width: '30%' } }
                        onChange={ handleMonthChange }
                        value={ selectedMonth }
                        picker="month"
                        format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                        placeholder='Pilih Bulan'
                    />
                </div>
                <Table
                    bordered
                    columns={ columns }
                    showSorterTooltip={ false }
                    dataSource={ filteredData }
                    loading={ loading }
                    rowKey="_id"
                    pagination={ { pageSize: 20 } }
                    size="small"
                    scroll={ { y: 400 } }
                />
            </div>
            <div>
                <Drawer
                    title="Edit Pembayaran"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseEdit }
                    visible={ drawerEdit }
                    width="50%"
                >
                    <EditLoanPayment
                        key={ `edit-form-key-${ editRecordId }` }
                        recordId={ editRecordId }
                        loanId={ idPayment }
                        onClose={ handleCloseEdit }
                        fetchData={ fetchData }
                    />
                </Drawer>
                <Drawer
                    title="Bayar Pinjaman"
                    placement="right"
                    closable={ true }
                    onClose={ handleCloseAdd }
                    visible={ drawerTambah }
                    width="40%"
                >
                    <NewLoanPayment onClose={ handleCloseAdd } fetchData={ fetchData } />
                </Drawer>
            </div>
        </div>
    );
};

export default LoanPaymentData;
