import React, { useState, useEffect } from 'react';
import { Table, Select, message, Button, DatePicker, InputNumber } from 'antd';
import axios from 'axios';
import { PiMicrosoftExcelLogoThin } from "react-icons/pi";
import { SlRefresh } from 'react-icons/sl';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend( isSameOrAfter );
dayjs.extend( isSameOrBefore );

const PiutangCust = () =>
{
    const [ dataPelanggan, setDataPelanggan ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ setData, setSetData ] = useState( [] );
    const [ totalUangMasuk, setTotalUangMasuk ] = useState( 0 );
    const [ totalPiutang, setTotalPiutang ] = useState( 0 );
    const [ lokasiTerpilih, setLokasiTerpilih ] = useState( 0 );
    const fetchData = async () =>
    {
        try
        {
            setLoading( true );
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/customers` );
            const data = response.data;
            const updateData = data.filter( customer =>
            {
                const totalDP = customer.pembayaranDP.reduce( ( total, dp ) => total + ( dp.jumlahByrDp || 0 ), 0 );
                const totalAngsuran = customer.pembayaranAngsuran.reduce( ( total, dp ) => total + ( dp.jumlahByrAngs || 0 ), 0 );
                return ( totalDP + totalAngsuran ) < ( ( customer.angsuranKavling * customer.tenorKavling ) + customer.uangMukaKavling );
            } );
            setDataPelanggan( updateData );
        } catch ( error )
        {
            console.error( 'Error fetching data:', error );
            message.error( 'Gagal memuat data pelanggan' );
        } finally
        {
            setLoading( false );
        }
    };
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const handleSelectLocation = ( value ) =>
    {
        setLokasiTerpilih( value );
    }
    const handleSelectMonth = ( date ) =>
    {
        setSelectedMonth( date );
    };
    useEffect( () =>
    {
        if ( selectedMonth )
        {
            const startOfMonth = dayjs( selectedMonth ).startOf( 'month' );
            const endOfMonth = dayjs( selectedMonth ).endOf( 'month' );

            const tableData = dataPelanggan.map( mp =>
            {
                const tenorJalan = ( mp.pembayaranAngsuran || [] ).filter( item => item.angsKe )
                    .filter( f => dayjs( f.tanggalByrAngs, 'DD/MM/YYYY' ).isSameOrBefore( endOfMonth ) );

                const angsTerbayar = ( mp.pembayaranAngsuran || [] )
                    .filter( item => dayjs( item.tanggalByrAngs, 'DD/MM/YYYY' ).isSameOrBefore( selectedMonth.endOf( 'month' ) ) )
                    .reduce( ( total, item ) => total + ( item.jumlahByrAngs || 0 ), 0 );

                const dpTerbayar = ( mp.pembayaranDP || [] )
                    .filter( item => dayjs( item.tanggalByrDp, 'DD/MM/YYYY' ).isSameOrBefore( selectedMonth.endOf( 'month' ) ) )
                    .reduce( ( total, item ) => total + ( item.jumlahByrDp || 0 ), 0 );

                const tempoTerbayar = ( mp.pembayaranTempo || [] )
                    .filter( item => dayjs( item.tanggalByrTempo, 'DD/MM/YYYY' ).isSameOrBefore( selectedMonth.endOf( 'month' ) ) )
                    .reduce( ( total, item ) => total + ( item.jumlahByrTempo || 0 ), 0 );

                const dendaTerbayar = ( mp.pembayaranAngsuran || [] )
                    .filter( item => dayjs( item.tanggalByrAngs, 'DD/MM/YYYY' ).isSameOrBefore( selectedMonth.endOf( 'month' ) ) )
                    .reduce( ( total, item ) => total + ( item.dendaAngs || 0 ), 0 );

                const totalHarga = ( mp.jenisPembelian === 'CASH' || mp.jenisPembelian === 'CASH TEMPO' )
                    ? mp.hargaKavling
                    : ( mp.angsuranKavling * mp.tenorKavling ) + ( mp.uangMukaKavling + ( mp.dendaCustomer || 0 ) ) - mp.potonganHarga;

                const totalTerbayar = angsTerbayar + dpTerbayar + tempoTerbayar + mp.jumlahUTJ + dendaTerbayar;
                const awalAngs = mp.pembayaranAngsuran?.[ 0 ]?.tanggalByrAngs !== undefined ? mp.pembayaranAngsuran[ 0 ]?.tanggalByrAngs : undefined;
                const awalDP = mp.pembayaranDP?.[ 0 ]?.tanggalByrDp !== undefined ? mp.pembayaranDP[ 0 ]?.tanggalByrDp : undefined;
                const awalTempo = mp.pembayaranTempo?.[ 0 ]?.tanggalByrTempo !== undefined ? mp.pembayaranTempo[ 0 ]?.tanggalByrTempo : undefined;

                return {
                    ...mp,
                    blok: mp.blokKavling === 'TANPABLOK' ? mp.noKavling : mp.blokKavling + mp.noKavling,
                    sisaTenor: mp.tenorKavling - tenorJalan.length,
                    BelumDibayar: totalHarga - totalTerbayar,
                    TotalDibayar: totalTerbayar,
                    tanggalAmbil: ( mp.tanggalBooking !== 'Invalid Date' && mp.tanggalBooking !== undefined )
                        ? mp.tanggalBooking
                        : ( mp.tanggalIJB !== 'Invalid Date' && mp.tanggalIJB !== undefined )
                            ? mp.tanggalIJB
                            : ( awalAngs !== 'Invalid Date' && awalAngs !== undefined )
                                ? awalAngs
                                : ( awalDP !== 'Invalid Date' && awalDP !== undefined )
                                    ? awalDP
                                    : ( awalTempo !== 'Invalid Date' && awalTempo !== undefined )
                                        ? awalTempo
                                        : undefined
                };
            } );
            const filteredTable = lokasiTerpilih
                ? tableData
                    .filter( item => item.lokasiKavling === lokasiTerpilih )
                    .filter( f => dayjs( f.tanggalAmbil, 'DD/MM/YYYY' )
                        .isSameOrBefore( endOfMonth ) )
                : tableData
                    .filter( f => dayjs( f.tanggalAmbil, 'DD/MM/YYYY' )
                        .isSameOrBefore( endOfMonth ) );
            const totalUM = filteredTable.reduce( ( total, item ) => total + ( item.TotalDibayar || 0 ), 0 );
            const totalPT = filteredTable.reduce( ( total, item ) => total + ( item.BelumDibayar || 0 ), 0 );
            setSetData( filteredTable );
            setTotalPiutang( totalPT );
            setTotalUangMasuk( totalUM );
        }
    }, [ dataPelanggan, selectedMonth, lokasiTerpilih ] )
    const columns = [
        {
            title: 'Blok Kavling',
            dataIndex: 'blok',
            align: 'center',
            width: '7%',
            sorter: ( a, b ) =>
            {
                const regex = /^([a-zA-Z]*)(\d*)$/;

                const aMatch = a.blok.match( regex ) || [];
                const bMatch = b.blok.match( regex ) || [];

                const aText = aMatch[ 1 ] || '';
                const aNumber = aMatch[ 2 ] !== '' ? parseInt( aMatch[ 2 ], 10 ) : 0;

                const bText = bMatch[ 1 ] || '';
                const bNumber = bMatch[ 2 ] !== '' ? parseInt( bMatch[ 2 ], 10 ) : 0;

                if ( aText < bText ) return -1;
                if ( aText > bText ) return 1;
                return aNumber - bNumber;
            },
            sortDirections: [ 'ascend', 'descend' ],
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Pelanggan</p>,
            dataIndex: 'namaCustomer',
            width: 'auto',
            align: 'left',
            sorter: ( a, b ) => a.namaCustomer.localeCompare( b.namaCustomer ),
        },
        {
            title: 'Tenor',
            width: 'auto',
            align: 'center',
            children: [
                {
                    title: 'Jumlah',
                    dataIndex: 'tenorKavling',
                    width: '10%',
                    align: 'center',
                },
                {
                    title: 'Sisa',
                    dataIndex: 'sisaTenor',
                    width: '5%',
                    align: 'center',
                    sorter: ( a, b ) => a.sisaTenor - b.sisaTenor,
                },
            ],
        },
        {
            title: 'Uang Masuk',
            dataIndex: 'TotalDibayar',
            width: '20%',
            align: 'center',
            sorter: ( a, b ) => a.TotalDibayar - b.TotalDibayar,
            render: value => convertToIDR( value ),
        },
        {
            title: 'Piutang',
            dataIndex: 'BelumDibayar',
            width: '20%',
            align: 'center',
            sorter: ( a, b ) => a.BelumDibayar - b.BelumDibayar,
            render: value => convertToIDR( value ),
        },
    ]
    const exportToExcel = () =>
    {
        const ws = XLSX.utils.json_to_sheet( dataPelanggan.map( item => ( {
            'Blok': item.blokKavling === 'TANPABLOK' ? item.noKavling : item.blokKavling + item.noKavling,
            'Nama Pelanggan': item.namaCustomer,
            'Tenor': item.tenorKavling,
            'Sisa': item.sisaTenor, // Perbaiki key jika diperlukan
            'Uang Masuk': item.TotalDibayar,
            'Piutang': item.BelumDibayar,
        } ) ), {
            header: [ "Blok", "Nama Pelanggan", "Tenor", "Sisa", "Uang Masuk", "Piutang" ]
        } );

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet( wb, ws, `${ lokasiTerpilih ? `Piutang ${ lokasiTerpilih }` : 'Piutang Pelanggan' }` );
        XLSX.writeFile( wb, `${ lokasiTerpilih ? `Piutang Pelanggan - ${ lokasiTerpilih }` : 'Piutang Pelanggan' }.xlsx` );
    };
    const handleRefresh = () =>
    {
        fetchData();
    }

    return (
        <div>
            <div style={ { padding: '0 5px' } }>
                <div style={ { padding: '10px 0' } }>
                    <div style={ {
                        display: 'flex',
                        gap: 5
                    } }>
                        <Button
                            style={ { padding: 5 } }
                            onClick={ handleRefresh }
                            icon={ <SlRefresh /> } />
                        <InputNumber
                            style={ { width: '100%' } }
                            value={ totalUangMasuk >= 1 && convertToIDR( totalUangMasuk ) }
                            placeholder={ totalUangMasuk < 1 && 'Tidak ada uang masuk' }
                            addonBefore={ totalUangMasuk >= 1 && 'Uang Masuk' }
                            readOnly />
                        <InputNumber
                            style={ { width: '100%' } }
                            value={ totalPiutang >= 1 && convertToIDR( totalPiutang ) }
                            placeholder={ totalPiutang < 1 && 'Tidak ada piutang' }
                            addonBefore={ totalPiutang >= 1 && 'Piutang' }
                            readOnly />
                        <DatePicker.MonthPicker
                            style={ { width: '35%' } }
                            onChange={ handleSelectMonth }
                            allowClear={ false }
                            value={ selectedMonth }
                            picker="month"
                            format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                            placeholder='Pilih Bulan'
                        />
                        <Select
                            style={ { width: '25%' } }
                            placeholder='Lokasi'
                            onChange={ handleSelectLocation }
                            allowClear
                            options={ [
                                { value: 'PETAL', label: 'PETAL' },
                                { value: 'DOMAS', label: 'DOMAS' },
                                { value: 'TANJUNG1', label: 'TANJUNG1' },
                                { value: 'TANJUNG2', label: 'TANJUNG2' },
                            ] } />
                        <Button
                            style={ { padding: 10 } }
                            onClick={ exportToExcel }
                            icon={ <PiMicrosoftExcelLogoThin /> } />
                    </div>
                </div>
                <Table
                    columns={ columns }
                    dataSource={ setData }
                    scroll={ { y: 350 } }
                    bordered
                    size="small"
                    loading={ loading }
                    showSorterTooltip={ false }
                />
            </div>
        </div>
    );
};

export default PiutangCust;
