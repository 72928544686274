import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Empty, List, message } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/id'; // Import bahasa Indonesia untuk dayjs
dayjs.locale( 'id' ); // Set lokal dayjs ke bahasa Indonesia

const DataKomisiCek = ( { recordId, onClose, fetchData } ) =>
{
    const [ dataKms, setDataKms ] = useState( [] );

    useEffect( () =>
    {
        const fetchDataById = async () =>
        {
            try
            {
                const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/sales/${ recordId }` );
                const data = response.data;
                const komisiData = data.dataKomisi;
                setDataKms( komisiData );
            } catch ( error )
            {
                message.error( 'Gagal mengambil data dari server' );
                console.error( 'Error fetching data:', error );
            }
        };

        if ( recordId )
        {
            fetchDataById();
        }
    }, [ recordId ] );

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
        } ).format( value );
    };

    const formatTanggal = ( tanggal ) =>
    {
        return dayjs( tanggal ).format( 'dddd DD MMMM YYYY' );
    };
    return (
        <div>
            { dataKms.length > 0 ? (
                <List
                    bordered
                    dataSource={ dataKms }
                    header="Riwayat Komisi"
                    renderItem={ ( item ) => (
                        <List.Item key={ item.komisiKe }>
                            <div>
                                <h4 style={ { margin: 0 } }>{ `Komisi Ke ${ item.komisiKe }` }</h4>
                                <p style={ { fontWeight: 'bold' } }>{ convertToIDR( item.jumlahByrKms ) }</p>
                                <p>Tanggal: { formatTanggal( item.tanggalByrKms ) }</p>
                            </div>
                        </List.Item>
                    ) }
                />
            ) : (
                <Empty description="Tidak ada data komisi" />
            ) }
        </div>
    );
};

export default DataKomisiCek;
