import React, { useEffect, useState } from 'react';
import { Table, message, DatePicker, Button, InputNumber } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';

const Pengeluaran = () =>
{
    const [ data, setData ] = useState( [] );
    const [ loading, setLoading ] = useState( false );
    const [ filteredData, setFilteredData ] = useState( [] );
    const [ paidTypeFilters, setPaidTypeFilters ] = useState( [] );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ totalPayment, setTotalPayment ] = useState();
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const responses = await axios.all( [
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/lahan` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/materials` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/sales` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/otherPayment` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/gajiTukang` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/employeeList` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/pengeluaranLain` ),
                axios.get( `${ process.env.REACT_APP_LOCAL_API }/loanData` ),
            ] );

            // Menggunakan destructuring untuk mengambil data dari respons
            const [ lahan, materials, sales, otherPayment, gajiTukang, employeeList, pTT, loanData, ] = responses.map( response => response.data );
            const dataLoan = loanData.flatMap( item =>
                item.riwayatPinjaman.map( db => ( {
                    namaNasabah: item.namaNasabah,
                    jumlahPinjam: db.loanValue,
                    tanggalPinjam: db.loanDate
                } ) )
            );
            const dataLahan = lahan.flatMap( item =>
                item.landPayment.map( db => ( {
                    namaPetani: item.farmerName,
                    kompensasiLhn: db.kompensasiLhn,
                    jumlahByrLhn: db.jumlahByrLhn,
                    tanggalByrLhn: db.tanggalByrLhn,
                    kodeLahan: item.landCode,
                } ) )
            )
            const dataSales = sales.flatMap( item =>
                item.dataKomisi.map( db => ( {
                    namaSales: item.namaSales,
                    tanggalByrKms: db.tanggalByrKms,
                    jumlahByrKms: db.jumlahByrKms,
                    lahanUser: item.lahanUser,
                    noKavUser: item.noKavUser,
                    blokKavUser: item.blokKavUser || '',
                } ) )
            )
            const employeeData = employeeList.flatMap( fm =>
                fm.dataSalary.map( mp => ( {
                    employeeName: fm.employeeName,
                    salary: mp.salary + mp.additionalSalary,
                    dateSalary: mp.dateSalary,
                } ) )
            )
            const combinedData = [
                ...dataLahan,
                ...materials,
                ...dataSales,
                ...otherPayment,
                ...gajiTukang,
                ...employeeData,
                ...pTT,
                ...dataLoan,
            ];

            // Memetakan data dengan tambahan informasi
            const updatedData = combinedData.map( db => ( {
                ...db,
                infoPaid: db.namaPetani ||
                    db.namaMaterial ||
                    db.namaSales ||
                    db.nama ||
                    ( db.tanggalGaji && 'GAJI TUKANG' ) ||
                    db.employeeName ||
                    db.catatanPTT ||
                    db.namaNasabah,
                tanggal: db.tanggalByrLhn ||
                    db.tanggalBeliMaterial ||
                    db.tanggalByrKms ||
                    db.tanggalKasih ||
                    db.tanggalGaji ||
                    db.dateSalary ||
                    db.tanggalPTT ||
                    db.tanggalPinjam,
                jumlah: db.jumlahByrLhn ||
                    db.hargaMaterial ||
                    db.jumlahByrKms ||
                    db.jumlahKasih ||
                    db.totalPembayaranGaji ||
                    db.salary ||
                    db.hargaPTT ||
                    db.jumlahPinjam,
                paidType: ( db.jumlahByrLhn && ( 'BAYAR LAHAN ' + db.kodeLahan ) ) ||
                    ( db.hargaMaterial && 'MATERIAL' ) ||
                    ( db.jumlahByrKms && ( 'FEE ' + db.blokKavUser + db.noKavUser + ' ' + db.lahanUser ) ) ||
                    db.jenis ||
                    ( db.tanggalGaji && 'GAJI TUKANG' ) ||
                    ( db.dateSalary && 'GAJI KARYAWAN' ) ||
                    ( db.hargaPTT && 'PENGELUARAN LAIN' ) ||
                    ( db.jumlahPinjam && 'KASBON' ),
            } ) );
            setData( updatedData );
            setPaidTypeFilters( getUniquePaidTypes( updatedData ) );
        } catch ( error )
        {
            message.error( 'Gagal mengambil data dari server' );
        }
        setLoading( false );
    };
    const getUniquePaidTypes = ( data ) =>
    {
        const uniqueTypes = [ ...new Set( data.map( item => item.paidType ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const handleMonthChange = ( date ) =>
    {
        setSelectedMonth( date );
    }
    useEffect( () =>
    {
        if ( selectedMonth )
        {
            const filterData = data.filter( item => dayjs( item.tanggal, 'DD/MM/YYYY' ).isSame( selectedMonth, 'month' ) );
            const paymentData = filterData.reduce( ( total, item ) => total + item.jumlah, 0 );
            setFilteredData( filterData );
            setTotalPayment( paymentData );
        }
    }, [ data, selectedMonth ] );
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
        } ).format( value );
    };
    useEffect( () =>
    {
        fetchData();
    }, [] );
    const handleRefresh = () =>
    {
        fetchData();
    };
    const columns = [
        {
            title: 'No',
            key: 'no',
            width: '5%',
            align: 'center',
            render: ( text, record, index ) => index + 1,
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Item</p>,
            dataIndex: 'infoPaid',
            align: 'left',
            width: '30vw',
        },
        {
            title: 'Tanggal Bayar',
            dataIndex: 'tanggal',
            align: 'center',
            width: '10vw',
            sorter: ( a, b ) => dayjs( a.tanggal, 'DD/MM/YYYY' ).diff( dayjs( b.tanggal, 'DD/MM/YYYY' ) ),
            sortDirections: [ 'descend', 'ascend' ],
            defaultSorter: 'ascend'
        },
        {
            title: 'Harga',
            dataIndex: 'jumlah',
            width: 'auto',
            align: 'center',
            render: value => convertToIDR( value ),
            sorter: ( a, b ) => a.jumlah - b.jumlah,
        },
        {
            title: 'Pengeluaran',
            dataIndex: 'paidType',
            width: 'auto',
            align: 'center',
            filters: paidTypeFilters,
            onFilter: ( value, record ) => record.paidType.includes( value ),
        },
    ];
    return (
        <div>
            <div style={ { padding: '0 5px' } }>
                <div style={ { display: 'flex', padding: '10px 0', gap: 5, alignItems: 'center' } }>
                    <Button type="default" onClick={ handleRefresh } >
                        Refresh
                    </Button>
                    <DatePicker.MonthPicker
                        placeholder='Tanggal'
                        onChange={ handleMonthChange }
                        format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                        picker='month'
                        allowClear={ false }
                        value={ selectedMonth }
                    />
                    <InputNumber
                        style={ {
                            display: 'flex',
                            width: '100%'
                        } }
                        readOnly
                        placeholder={ totalPayment < 1 && 'Tidak ada pengeluaran' }
                        addonBefore={ totalPayment >= 1 && 'Total' }
                        value={ totalPayment >= 1 && convertToIDR( totalPayment ) } />
                </div>
                <Table
                    bordered
                    showSorterTooltip={ false }
                    columns={ columns }
                    dataSource={ filteredData }
                    loading={ loading }
                    size='small'
                    scroll={ { y: 380 } }
                />
            </div>
        </div>
    );
};

export default Pengeluaran;
