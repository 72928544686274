import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Spin, Select, InputNumber } from 'antd';
import axios from 'axios';
import CreateAxiosInstance from '../../../Authentication/contexts/axiosConfig';
const { Option } = Select;
const AddNewFinance = ( { onClose, fetchData } ) =>
{
    const apiUrl = process.env.REACT_APP_LOCAL_API;
    const axiosConfig = CreateAxiosInstance();
    const [ form ] = Form.useForm();
    const [ loading, setLoading ] = useState( false );
    const [ listKav, setListKav ] = useState( [] );
    const [ filteredLocation, setFilteredLocation ] = useState( [] );
    const [ filteredBlock, setFilteredBlock ] = useState( [] );
    const fetchListData = async () =>
    {
        setLoading( true )
        try
        {
            const [ listKavling, listSales ] = await Promise.all( [
                axios.get( `${ apiUrl }/customers` ),
                axios.get( `${ apiUrl }/sales` )
            ] );
            const dataKavling = listKavling.data.map( mp => ( {
                namaCustomer: mp.namaCustomer,
                blokKavling: mp.blokKavling,
                noKavling: mp.noKavling,
                lokasiKavling: mp.lokasiKavling,
                kavlingCode: `${ mp.blokKavling !== 'TANPABLOK' ? mp.blokKavling : '' }${ mp.noKavling }${ mp.lokasiKavling }`
            } ) );
            const dataSales = listSales.data.map( mp => ( {
                salesCode: `${ mp.blokKavUser !== 'TANPABLOK' ? mp.blokKavUser : '' }${ mp.noKavUser }${ mp.lahanUser }`
            } ) );
            const data = dataKavling.filter( dk =>
                !dataSales.some( ds => ds.salesCode === dk.kavlingCode )
            );
            setListKav( data );
        } catch ( error )
        {
            console.error( error )
        } finally
        {
            setLoading( false );
        }
    };
    useEffect( () =>
    {
        fetchListData();
    }, [] );

    useEffect( () =>
    {
        setFilteredBlock( [] );
    }, [ filteredLocation ] );
    const handleLocationSelect = ( value ) =>
    {
        const locationFilter = listKav.filter( lf => lf.lokasiKavling === value );
        setFilteredLocation( locationFilter );
        form.resetFields( [
            'blokKavUser',
            'noKavUser',
            'totalKomisi',
        ] );
        if ( locationFilter.length > 0 )
        {
            message.success( `${ locationFilter.length } blok ditemukan` )
        } else
        {
            message.info( 'Tidak ada blok ditemukan' )
        }
    };
    const handleBlockSelect = ( block ) =>
    {
        const blockFilter = filteredLocation.filter( fl => fl.blokKavling === block )
        setFilteredBlock( blockFilter );
        form.resetFields( [
            'noKavUser',
            'totalKomisi',
        ] );
        if ( blockFilter.length > 0 )
        {
            message.success( `${ blockFilter.length } kavling ditemukan` )
        } else
        {
            message.info( 'Tidak ada kavling ditemukan' )
        }
    };
    const onFinish = async ( values ) =>
    {
        const dataKomisi = {
            ...values,
            namaSales: values.namaSales,
            blokKavUser: values.blokKavUser || 'TANPABLOK',
            noKavUser: values.noKavUser,
            lahanUser: values.lahanUser,
            totalKomisi: values.totalKomisi,
        };
        try
        {
            setLoading( true );
            await axiosConfig.post( `${ apiUrl }/sales`, dataKomisi );
            message.success( 'Data baru berhasil ditambahkan' );
            form.resetFields();
            onClose();
            fetchData();
        } catch ( error )
        {
            if ( error.response && error.response.data && error.response.data.message )
            {
                message.error( error.response.data.message );
            } else
            {
                message.error( 'Gagal menambahkan data baru' );
            }
        } finally
        {
            setLoading( false );
        }
    };

    return (
        <Spin spinning={ loading }>
            <Form form={ form } onFinish={ onFinish } layout="vertical">
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="namaSales"
                        label="Nama Marketing"
                        rules={ [ { required: true, message: 'Nama marketing diperlukan!' } ] }
                    >
                        <Input placeholder="Nama Marketing" />
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="lahanUser"
                        label="Lokasi Kavling"
                        rules={ [ { required: true, message: 'Lokasi kavling diperlukan!' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder="Pilih Lokasi Kavling"
                            onChange={ handleLocationSelect }>
                            {
                                [ ...new Set( listKav.map( loc => loc.lokasiKavling ) ) ]
                                    .map( ( lokasi, index ) => (
                                        <Option key={ index } value={ lokasi }>
                                            { lokasi }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className='payments-flex'>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="blokKavUser"
                        label="Blok Kavling"
                        normalize={ ( value ) => value && value.toUpperCase() }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih Blok'
                            onChange={ handleBlockSelect }
                        >
                            {
                                [ ...new Set( filteredLocation.map( fl => fl.blokKavling ) ) ]
                                    .map( ( blok, index ) => (
                                        <Option key={ index } value={ blok }>
                                            { blok }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={ { width: '100%' } }
                        name="noKavUser"
                        label="Nomor Kavling"
                        rules={ [ { required: true, message: 'Nomor kavling diperlukan!' } ] }
                    >
                        <Select
                            style={ { width: '100%' } }
                            placeholder='Pilih No Kavling'
                        >
                            {
                                [ ...new Set( filteredBlock.map( fl => fl.noKavling ) ) ]
                                    .map( ( noKav, index ) => (
                                        <Option key={ index } value={ noKav }>
                                            { noKav }
                                        </Option>
                                    ) )
                            }
                        </Select>
                    </Form.Item>
                </div>
                <Form.Item
                    style={ { width: '100%' } }
                    name="totalKomisi"
                    label="Jumlah Komisi"
                    rules={ [ { required: true, message: 'Jumlah komisi diperlukan!' } ] }
                >
                    <InputNumber
                        addonBefore='Rp'
                        style={ { width: '100%' } }
                        placeholder="Jumlah Komisi" />
                </Form.Item>
                <Button type='primary' htmlType='submit' >
                    Tambah Data
                </Button>
            </Form>
        </Spin>
    );
};

export default AddNewFinance;
