import React, { useEffect, useState } from 'react';
import { Table, Button, DatePicker, Drawer, Select, message, InputNumber } from 'antd';
import axios from 'axios';
import { PiCursorClickThin } from 'react-icons/pi';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import 'dayjs/locale/id'; // Import bahasa Indonesia untuk dayjs
import { SlRefresh } from 'react-icons/sl';
import BayarDP from './DpBayar';
import EditDP from './DpEdit';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.locale( 'id' );
dayjs.extend( isSameOrBefore );
dayjs.extend( isSameOrAfter );

const CustomersTable = () =>
{
    const [ loading, setLoading ] = useState( false );
    const [ drawerEdit, setDrawerEdit ] = useState( false );
    const [ customerData, setCustomerData ] = useState( [] );
    const [ selectedAction, setSelectedAction ] = useState( "" );
    const [ filterLocation, setFilterLocation ] = useState( [] );
    const [ drawerPayment, setDrawerPayment ] = useState( false );
    const [ filteredData, setFilteredData ] = useState( [] );
    const [ selectedMonth, setSelectedMonth ] = useState( dayjs() );
    const [ dpTerbayar, setDpTerbayar ] = useState();
    const [ dpTertagih, setDpTertagih ] = useState();
    const fetchData = async () =>
    {
        setLoading( true );
        try
        {
            const response = await axios.get( `${ process.env.REACT_APP_LOCAL_API }/customers` );
            const data = response.data;
            const updateData = data.filter( customer =>
            {
                const totalDP = customer.pembayaranDP.reduce( ( total, dp ) => total + ( dp.jumlahByrDp || 0 ), 0 );
                return totalDP < customer.uangMukaKavling;
            } );
            setCustomerData( updateData );
            setFilterLocation( getUniqueLocation( updateData ) );
        } catch ( error )
        {
            console.error( 'Error fetching data:', error );
        } finally
        {
            setLoading( false );
        }
    };
    useEffect( () =>
    {
        fetchData()
    }, [] )
    const handleRefresh = () =>
    {
        fetchData();
    };
    const getUniqueLocation = filteredData =>
    {
        const uniqueTypes = [ ...new Set( filteredData.map( item => item.lokasiKavling ) ) ];
        return uniqueTypes.map( type => ( { text: type, value: type } ) );
    };
    const handleMonthChange = ( date ) =>
    {
        setSelectedMonth( date );
    };
    useEffect( () =>
    {
        if ( selectedMonth )
        {
            const startOfMonth = dayjs( selectedMonth ).startOf( 'month' );
            const endOfMonth = dayjs( selectedMonth ).endOf( 'month' );
            const filterData = customerData.map( db =>
            {
                const pembayaranDPFiltered = db.pembayaranDP.filter( item =>
                    dayjs( item.tanggalByrDp, 'DD/MM/YYYY' ).isSame( selectedMonth, 'month' )
                );
                const lastPembayaranDPFiltered = db.pembayaranDP
                    .filter( item => dayjs( item.tanggalByrDp, 'DD/MM/YYYY' ).isBefore( selectedMonth, 'month' ) )
                    .slice( -1 );
                return {
                    tglBooking: db.tanggalBooking,
                    tglIJB: db.tanggalIJB,
                    nama: db.namaCustomer,
                    dp: db.uangMukaKavling,
                    tertagih: db.uangMukaKavling - db.pembayaranDP
                        .filter( item => dayjs( item.tanggalByrDp, 'DD/MM/YYYY' ).isSameOrBefore( selectedMonth, 'month' ) )
                        .reduce( ( total, item ) => total + ( item.jumlahByrDp || 0 ), 0 ),
                    blok: ( db.blokKavling === 'TANPABLOK'
                        ? db.noKavling : db.blokKavling + db.noKavling ),
                    lokasi: db.lokasiKavling,
                    jumlahByr: pembayaranDPFiltered.length > 0
                        ? pembayaranDPFiltered.map( item => convertToIDR( item.jumlahByrDp ) ).join( ", " )
                        : convertToIDR( 0 ),
                    tanggalByr: pembayaranDPFiltered.length > 0
                        ? pembayaranDPFiltered.map( item => item.tanggalByrDp ).join( ", " )
                        : '',
                    metodeByr: pembayaranDPFiltered.length > 0
                        ? pembayaranDPFiltered.map( item => item.metodeBayarDp ).join( ", " )
                        : '',
                    angs: pembayaranDPFiltered.length > 0
                        ? pembayaranDPFiltered.map( item => item.dpKe ).join( ", " )
                        : lastPembayaranDPFiltered.length > 0
                            ? lastPembayaranDPFiltered.map( item => item.dpKe + 1 )
                            : 1
                };
            } );
            const setData = filterData.filter( item =>
                dayjs( item.tglBooking ? item.tglBooking : item.tglIJB, 'DD/MM/YYYY' ).isSameOrBefore( endOfMonth )
            );
            const totalByrDp = setData.reduce( ( total, item ) =>
            {
                const jumlahByrNumbers = item.jumlahByr
                    .split( ', ' )
                    .map( value => parseFloat( value.replace( /[^\d]/g, '' ) ) )
                    .filter( value => !isNaN( value ) );
                return total + jumlahByrNumbers.reduce( ( acc, value ) => acc + value, 0 );
            }, 0 );
            const sisaDp = setData.reduce( ( total, item ) => total + ( item.tertagih || 0 ), 0 )
            setDpTerbayar( totalByrDp );
            setDpTertagih( sisaDp );
            setFilteredData( setData );
        } else
        {
            return true
        }
    }, [ customerData, selectedMonth ] );
    const handleSelectChange = ( value ) =>
    {
        if ( value )
        {
            setSelectedAction( value );
        } else
        {
            setSelectedAction( null );
        }
    };
    const handleSelectAction = () =>
    {
        if ( selectedAction === 'bayarDP' )
        {
            handlePayment();
        } if ( selectedAction === 'editDP' )
        {
            handleEdit();
        } if ( selectedAction === 'export' )
        {
            exportToExcel();
        }
    }
    const handleEdit = () =>
    {
        setDrawerEdit( true );
    };
    const handleCloseDrawer = () =>
    {
        setDrawerEdit( false );
    };
    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0,
        } ).format( value );
    };
    const handlePayment = () =>
    {
        setDrawerPayment( true );
    }
    const handleClosePayment = () =>
    {
        setDrawerPayment( false );
    }
    const columns = [
        {
            title: 'Blok Kavling',
            dataIndex: 'blok',
            align: 'center',
            width: '8%',
            sorter: ( a, b ) => a.blok - b.blok,
            sortDirections: [ 'descend', 'ascend' ],
            defaultSortOrder: 'ascend',
        },
        {
            title: <p style={ { textAlign: 'center' } }>Nama Pelanggan</p>,
            dataIndex: 'nama',
            align: 'left',
            width: 'auto',
            sorter: ( a, b ) => a.nama.localeCompare( b.nama ),
        },
        {
            title: 'Lokasi',
            dataIndex: 'lokasi',
            align: 'center',
            width: '9%',
            filters: filterLocation,
            onFilter: ( value, record ) => record.lokasi.includes( value ),
        },
        {
            title: 'Uang Muka',
            dataIndex: 'dp',
            align: 'center',
            width: '10%',
            render: ( value ) => convertToIDR( value ),
        },
        {
            title: 'Sisa DP',
            dataIndex: 'tertagih',
            align: 'center',
            width: '10%',
            render: ( value ) => convertToIDR( value ),
        },
        {
            title: 'Pembayaran',
            align: 'center',
            width: 'auto',
            children: [
                {
                    title: 'Ke',
                    dataIndex: 'angs',
                    align: 'center',
                    width: '8%',
                },
                {
                    title: 'Jumlah Bayar',
                    dataIndex: 'jumlahByr',
                    align: 'center',
                    width: '10%',
                },
                {
                    title: 'Tanggal Bayar',
                    dataIndex: 'tanggalByr',
                    align: 'center',
                    width: '10%',
                    sorter: ( a, b ) => dayjs( a.tanggalByr, 'DD/MM/YYYY' ) - dayjs( b.tanggalByr, 'DD/MM/YYYY' ),
                },
                {
                    title: 'Metode',
                    dataIndex: 'metodeByr',
                    align: 'center',
                    width: '8%',
                },
            ],
        },
    ];
    const exportToExcel = () =>
    {
        if ( !filteredData || filteredData.length === 0 )
        {
            message.error( 'Tidak ada data untuk diekspor' );
            return;
        }
        const ws = XLSX.utils.json_to_sheet( filteredData.map( item => ( {
            'Blok': item.blok,
            'Nama Pelanggan': item.nama,
            'Uang Muka': item.dp,
            'Ke': item.angs,
            'Jumlah Bayar': parseFloat( item.jumlahByr.replace( /[^\d]/g, '' ) ) === 0 ? undefined
                : parseFloat( item.jumlahByr.replace( /[^\d]/g, '' ) ),
            'Tanggal Bayar': item.tanggalByr,
            'Metode Bayar': item.metodeByr,
        } ) ), { header: [ "Blok", "Nama Pelanggan", "Uang Muka", "Ke", "Jumlah Bayar", "Tanggal Bayar", "Metode Bayar" ] } );
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet( wb, ws, 'Data Customers' );
        XLSX.writeFile( wb, `Data DP ${ selectedMonth.format( 'MMMM YYYY' ) }.xlsx` );
    };
    return (
        <div style={ { padding: '0 5px' } }>
            <div style={ { display: 'flex', gap: 5, padding: '10px 0' } }>
                <Button
                    style={ { padding: 5 } }
                    onClick={ handleRefresh }
                    icon={ <SlRefresh /> } />
                <Button
                    style={ { padding: 5 } }
                    type='primary'
                    onClick={ handleSelectAction }
                    icon={ <PiCursorClickThin /> } />
                <Select
                    style={ { width: '50%' } }
                    placeholder='Pilih Aksi'
                    onChange={ handleSelectChange }
                    allowClear
                    options={ [
                        { value: 'bayarDP', label: 'Bayar DP' },
                        { value: 'editDP', label: 'Edit DP' },
                        { value: 'export', label: 'Ekspor Ke Excel' },
                    ] } />
                <InputNumber
                    style={ { width: '100%' } }
                    value={ dpTerbayar >= 1 && convertToIDR( dpTerbayar ) }
                    placeholder={ dpTerbayar < 1 && 'Tidak ada dp terbayar' }
                    addonBefore={ dpTerbayar >= 1 && 'Terbayar' }
                    readOnly />
                <InputNumber
                    style={ { width: '100%' } }
                    value={ dpTertagih >= 1 && convertToIDR( dpTertagih ) }
                    placeholder={ !dpTertagih < 1 && 'Tidak ada dp tertagih' }
                    addonBefore={ dpTertagih >= 1 && 'Tertagih' }
                    readOnly />
                <DatePicker.MonthPicker
                    style={ { width: '50%' } }
                    onChange={ handleMonthChange }
                    value={ selectedMonth }
                    picker="month"
                    allowClear={ false }
                    format={ ( value ) => dayjs( value ).format( 'MMMM YYYY' ) }
                    placeholder='Pilih Bulan'
                />
            </div>
            <Table
                size="small"
                pagination={ { pageSize: 20 } }
                scroll={ { y: 340 } }
                dataSource={ filteredData }
                columns={ columns }
                rowKey="_id"
                loading={ loading }
                showSorterTooltip={ false }
            />
            <Drawer
                title="Edit Pembayaran DP"
                placement="right"
                closable={ true }
                onClose={ handleCloseDrawer }
                visible={ drawerEdit }
                width='40%'
            >
                <EditDP
                    onClose={ handleCloseDrawer }
                    fetchData={ fetchData }
                />
            </Drawer>
            <Drawer
                title="Bayar DP"
                placement="right"
                closable={ true }
                onClose={ handleClosePayment }
                visible={ drawerPayment }
                width='40%'
            >
                <BayarDP
                    onClose={ handleClosePayment }
                    fetchData={ fetchData }
                />
            </Drawer>
        </div>
    );
};

export default CustomersTable;
